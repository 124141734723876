import { useFetch } from '../../../../../utilities/hooks/useFetch';
import { getProviderOpenSlots } from '../../../../shared/service/API/provider';

export type AvailableSessionDurations = '900' | '1800' | '2700' | '3600';
export type ProviderOpenSlots = {
  displayDate: string;
  displayTime: string;
  displayTimeEnd: string;
  startTimeUtc: number;
  endTimeUtc: number;
};

export type IProviderOpenSlotsFull = Record<
  AvailableSessionDurations,
  ProviderOpenSlots[]
>;

export function useProviderOpenSlots(
  providerId: string,
  providerRole: string,
  clientId: string,
  meetingId?: string,
) {
  return useFetch<IProviderOpenSlotsFull>(
    ['provider', 'slots', providerId, providerRole, clientId, meetingId],
    () => getProviderOpenSlots(providerId, providerRole, clientId, meetingId),
    {
      refetchOnWindowFocus: true,
      retry: false,
    },
  );
}
