export enum RiskAssesment {
  NoRisk = 1,
  LowRisk = 2,
  MediumRisk = 3,
  HighRiskNonCritical = 5,
  HighRiskCritical = 4,
  NotAssessed = 6,
}

export const SOS_GUIDELINES_DATA = {
  fields: [
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_ONE',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_TWO',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_THREE',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_FOUR',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_FIVE',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_SIX',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_SEVEN',
  ],
};
