import Config from '../../../shared/Config';
import NetworkClient from '../../../v2/shared/service/Network';
import { beautifyUrl } from '../../../utilities/common/Path';
import { ConsumedResponseType, IConversationRequestBody } from '../../data-types/ClientTypes';

const client = new NetworkClient();

export const errorMessage = {
  generic: 'Unable to load the conversations data, please try again!',
};

export const getConversationsDetails = async (data?: {
  clientId?: string;
  serviceType?: string;
}) => {
  const response = await client.doPost(
    Config.api.chat.conversations,
    {},
    {},
    {
      userId: data?.clientId,
      providerRole: data?.serviceType,
    },
  );
  if (response?.success) {
    return response;
  }
  throw new Error(response?.data?.error?.message || errorMessage.generic);
};

// updates server messages Consumed status

export const setChatMessagesConsumed = async (
  userId: string,
): Promise<ConsumedResponseType> =>
  client.doPost(
    beautifyUrl(Config.api.chat.setChatMessageConsumed, [userId]),
    {},
  );

export const getConversationsDetailsv2 = async (body:IConversationRequestBody ) => {
  const response = await client.doPost(
    Config.apiV2.messages.conversationList,
    body,
  );
  if (response?.success) {
    return response;
  }
  throw new Error(response?.message || errorMessage.generic);
};
