import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';
import UserEmergencyContactCard from '../../../../../../v2/view/screens/ClientDetails/UserEmergencyContactCard';
import UserNotesCard from '../../../../../../v2/view/screens/ClientDetails/UserNotesCard';
import UserPresentingIssuesCard from '../../../../../../v2/view/screens/ClientDetails/UserPresentingIssuesCard';
import UserRiskLevelCard from '../../../../../../v2/view/screens/ClientDetails/UserRiskLevelCard';
import ClientDetailsContext from '../../../../../../v2/view/screens/ClientDetails/context/ClientDetailsContext';
import { defaultComponentsOrder } from '../../../../../../v2/view/screens/ClientDetails/constants';
import ClientOverviewCardLoader from '../../../../../../v2/view/screens/ClientDetails/ClientOverviewCardLoader';
import UserInfoCard from './UserInfoCard';
import { ReactComponent as CalendarIcon } from '../../../../../../v2/assets/CalendarWithoutDate.svg';

const ClientComponents = {
  notes: UserNotesCard,
  presentingIssue: UserPresentingIssuesCard,
  risk: UserRiskLevelCard,
  emergencyContact: UserEmergencyContactCard,
};

export function QuickOverview({ open }: { open: boolean }) {
  const { t } = useTranslation();
  const { clientDetailData, isLoading } = useContext(ClientDetailsContext);

  const ComponentsOrder = (clientDetailData?.order ??
    defaultComponentsOrder) as (keyof typeof ClientComponents)[];

  return (
    <section
    className={twMerge(
      'min-w-0 w-0 overflow-x-hidden opacity-0 transition-all duration-500 ease-in-out',
      open &&
        'min-w-[380px] w-[380px] h-[calc(100vh-92px)] overflow-y-auto border-l border-gray-300 opacity-100',
    )}
    >
      <div className="min-w-[380px] w-[380px]">
        {!clientDetailData || isLoading ? (
          <div className="p-4 flex flex-col gap-2">
            <ClientOverviewCardLoader variant="avatar" />
            <ClientOverviewCardLoader variant="card" />
            <ClientOverviewCardLoader variant="card" />
          </div>
        ) : (
          <div className="overflow-y-auto py-4">
            <UserInfoCard />
            {clientDetailData.nextSession ? (
              <section className="p-4">
                <div className="font-medium text-sm mb-2">{t('NEXT_SESSION_COMPONENT')}</div>
                <div className="bg-gray-50 text-xs rounded-md border border-gray-100 w-fit py-1 px-2 text-gray-600 flex items-center gap-2">
                  <CalendarIcon className="w-3 h-3 text-gray-400" />
                  {moment
                    .unix(clientDetailData.nextSession)
                    .format('DD MMM, hh:mm A')}{' '}
                  onwards
                </div>
              </section>
            ) : null}

            {ComponentsOrder.map((comp) => {
              const Component = ClientComponents?.[comp];
              return Component ? <Component key={comp} /> : null;
            })}
          </div>
        )}
      </div>
    </section>
  );
}
