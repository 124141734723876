import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ListboxOptionsProps,
} from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { Input } from './Input';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as ChevronDown } from '../../assets/ChevronDown.svg';
import { ReactComponent as Tick } from '../../assets/tick.svg';

export type SelectOption = {
  label: string;
  value: string;
  description?: string | JSX.Element;
  disabled?: boolean;
};

type SelectProps = {
  fullWidth?: boolean;
  options: SelectOption[];
  placeholder?: string;
  searchable?: boolean;
  className?: string;
  listBoxClassName?: string;
  disabled?: boolean;
  error?: boolean;
  listboxAnchor?: ListboxOptionsProps['anchor'];
} & (
  | {
      multiple?: undefined;
      selected?: string;
      onSelect: (updatedSelected: string) => void;
    }
  | {
      multiple: true;
      selected: string[];
      onSelect: (updatedSelected: string[]) => void;
    }
);

export function Select({
  fullWidth,
  options,
  multiple,
  selected,
  placeholder,
  onSelect,
  searchable,
  className,
  listBoxClassName,
  disabled = false,
  error = false,
  listboxAnchor,
}: SelectProps) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>('');

  const filteredOptions = options.filter((opt) =>
    opt.label.toLowerCase().includes(searchText.toLowerCase()),
  );

  const getSelectDisplayText = () => {
    if (multiple) {
      if (selected.length) {
        return (
          <div className="flex items-center gap-2">
            <span className="truncate">
              {options
                .filter((opt) => selected.includes(opt.value))
                .map((opt) => t(opt.label))
                .join(', ')}
            </span>
            <span className="bg-blue-700 text-xs text-white rounded-[50%] h-4 w-4 flex items-center justify-center  flex-shrink-0">
              {selected.length}
            </span>
          </div>
        );
      }
      return placeholder || 'Select';
    }
    if (selected) {
      return (
        options.find((opt) => opt.value === selected)?.label || placeholder
      );
    }
    return placeholder || 'Select';
  };
  return (
    <Listbox value={selected} multiple={multiple} onChange={onSelect}>
      <div className="relative">
        <ListboxButton
          className={twMerge(
            'relative cursor-pointer font-medium text-md min-w-48 max-w-48 rounded-lg bg-white py-2 pl-4 pr-12 text-left border border-gray-300 focus:border-primary-300 focus:outline-none focus:ring-4 focus:ring-primary-100',
            fullWidth && 'w-full',
            ['Select', placeholder].includes(
              getSelectDisplayText() as string,
            ) && 'text-gray-400 font-normal',
            disabled && 'bg-gray-100/50 pointer-events-none',
            error && 'border-error-300',
            className,
          )}
          disabled={disabled}
        >
          <span className="block truncate">{getSelectDisplayText()}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDown className="text-gray-400" />
          </span>
        </ListboxButton>

        <ListboxOptions
          anchor={listboxAnchor}
          className={twMerge(
            'absolute mt-1 z-10 max-h-60 overflow-auto rounded-md bg-white p-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm',
            fullWidth && 'w-full',
            listBoxClassName,
          )}
        >
          {searchable ? (
            <>
              <Input
                containerClassName="w-full mt-1"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                startIcon={<SearchIcon className="w-4'stroke-primary-600" />}
              />
              <hr className="my-3" />
            </>
          ) : null}
          {filteredOptions.length ? (
            filteredOptions.map(
              ({ label, value, description, disabled: optionDisabled }) => {
                const isSelected = multiple
                  ? selected.some((s) => s === value)
                  : value === selected;
                return (
                  <ListboxOption
                    key={label + value}
                    className={({ focus }) =>
                      twMerge(
                        'relative cursor-pointer my-1 transition-colors rounded-lg hover:bg-primary-100 select-none py-2 pl-4 pr-10',
                        focus
                          ? 'bg-primary-100 text-gray-900'
                          : 'text-gray-900',
                        isSelected && 'border border-primary-100 bg-primary-50',
                        optionDisabled && 'pointer-events-none',
                      )
                    }
                    value={value}
                    disabled={optionDisabled}
                  >
                    <span
                      className={twMerge(
                        'block truncate font-medium',
                        isSelected && 'text-primary-600',
                        optionDisabled && 'text-gray-400  ',
                      )}
                    >
                      {t(label)}
                    </span>
                    {description ? (
                      <span
                        className={twMerge(
                          'block truncate text-xs text-gray-500 font-normal',
                          isSelected && 'text-primary-600',
                          optionDisabled && 'text-gray-400',
                        )}
                      >
                        {description}
                      </span>
                    ) : null}
                    {isSelected ? (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-amber-600">
                        <Tick className="text-primary-600" />
                      </span>
                    ) : null}
                  </ListboxOption>
                );
              },
            )
          ) : (
            <div className="text-gray-400 text-center p-3 flex flex-col items-center font-normal">
              <SearchIcon className="w-6 text-gray-400 mb-5" />

              <div>
                Sorry, we couldn’t find any results matching your search.
              </div>
            </div>
          )}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
