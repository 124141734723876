/* eslint-disable react/jsx-props-no-spreading, react/no-unstable-nested-components */
import {
  ChevronProps,
  CustomComponents,
  DayPicker,
  defaultLocale,
  getDefaultClassNames,
  Matcher,
} from 'react-day-picker';
import 'react-day-picker/style.css';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as ChevronIcon } from '../../assets/rightChevron.svg';
import { getTimeZone } from '../../utilities/common/Date';

const dayLocaleStrings = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const monthLocaleStrings = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export interface DatePickerProps {
  disabled?: Matcher | Matcher[];
  selected?: Date;
  onSelect: (newDate?: Date) => void;
  components?: Partial<CustomComponents>;
  classNames?: {
    day?: string;
    today?: string;
    month_grid?: string;
    caption_label?: string;
    weekday?: string;
    disabled?: string;
    selected?: string;
    root?: string;
    chevron?: string;
  };
}

export function DatePicker({
  disabled,
  onSelect,
  selected,
  classNames,
  components,
}: DatePickerProps) {
  const defaultClassNames = getDefaultClassNames();

  const { t } = useTranslation();

  return (
    <DayPicker
      mode="single"
      timeZone={getTimeZone()}
      disabled={disabled}
      weekStartsOn={1}
      onSelect={onSelect}
      selected={selected}
      defaultMonth={selected}
      components={{
        Chevron: ({ orientation, className, ...props }: ChevronProps) => (
          <ChevronIcon
            {...props}
            className={twMerge(
              'text-gray-400',
              className,
              orientation === 'left' && 'rotate-180',
            )}
            orientation={orientation}
          />
        ),
        ...(components || {}),
      }}
      locale={{
        localize: {
          ...defaultLocale.localize,
          day: (day: number) => t(dayLocaleStrings[day]),
          month: (month: number) => t(monthLocaleStrings[month]),
        },
      }}
      classNames={{
        day: twMerge(
          'rounded-lg bg-primary-50 text-gray-700 transition-colors',
          classNames?.day,
        ),
        today: twMerge('text-gray-700', classNames?.today),
        month_grid: twMerge(
          'border-spacing-1 border-separate',
          classNames?.month_grid,
        ),
        disabled: twMerge('bg-white', classNames?.disabled),
        selected: twMerge('bg-primary-600 text-white', classNames?.selected), // Highlight the selected day
        root: twMerge(defaultClassNames.root, 'bg-white', classNames?.root), // Add a shadow to the root element
        chevron: twMerge(
          'text-gray-500 font-light border border-gray-300 rounded-lg',
          classNames?.chevron,
        ), // Change the color of the chevron
        caption_label: twMerge(
          'text-sm font-semibold inline-flex items-center',
          classNames?.caption_label,
        ),
        weekday: twMerge(
          'text-xs font-semibold text-center',
          classNames?.weekday,
        ),
      }}
    />
  );
}
