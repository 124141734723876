import moment from 'moment';
import { useContext, useMemo } from 'react';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';
import { ReactComponent as CopyIcon } from '../../../../../../v2/assets/copy.svg';
import ClientDetailsContext from '../../../../../../v2/view/screens/ClientDetails/context/ClientDetailsContext';
import { getCurrentLocale } from '../../../../../../i18n';
import ClientOverviewCardLoader from '../../../../../../v2/view/screens/ClientDetails/ClientOverviewCardLoader';
import Avatar from '../../../../../../v2/view/components/Avatar';
import { ClientGenderMappings } from '../../../../../../v2/shared/constant/Clients';
import { Tag } from '../../../../../../v2/view/components/Tag';
import { getDisplayDate } from '../../../../../../utilities/common/Date';
import { ClientMatchedOnDateFormat } from '../../../../../../v2/utilities/common/Date';
import { Button } from '../../../../../../v2/view/components/Button';
import { ReactComponent as LinkIcon } from '../../../../../../v2/assets/linkArrow.svg';

export default function UserInfoCard() {
  const { t } = useTranslation();
  const {
    clientDetailData,
    isLoading,
    encodedClientId,
    clientName,
    serviceType,
  } = useContext(ClientDetailsContext);

  const currentLocale = getCurrentLocale();

  const handleCopyIconClick = () => {
    navigator.clipboard.writeText(encodedClientId as string);
    toast.success(t('COPY_CASE_ID_SUCCESS_PROMPT'));
  };

  const ClientPersonalInfo = useMemo(() => {
    const { age = null, gender = null } = clientDetailData?.profile ?? {};
    const items = {
      age: { value: t('UNDISCLOSED') },
      gender: { value: t('UNDISCLOSED') },
      ORGANISATION: {
        value: clientDetailData?.profile?.isB2CUser
          ? t('B2C_CLIENT_ORG_STATE')
          : clientDetailData?.profile?.companyName ?? t('UNDISCLOSED'),
        className: 'col-span-3 [&>*:nth-last-child(1)]:truncate',
      },
      CASEID: {
        value: (
          <Tag
            variant="gray"
            className="flex rounded w-auto p-0 bg-white items-center justify-start gap-1 text-xs hover:bg-gray-50 active:bg-gray-100"
            onClick={handleCopyIconClick}
          >
            {encodedClientId}
            <CopyIcon className="cursor-pointer w-2 h-2" />
          </Tag>
        ),
      },
    };

    if (age) {
      items.age.value = t(age === 'NA' ? t('UNDISCLOSED') : t('AGE', { age }));
    }

    if (gender && ClientGenderMappings?.[gender]) {
      items.gender.value = t(
        gender === 'NA' ? t('UNDISCLOSED') : ClientGenderMappings?.[gender],
      );
    }

    return items;
  }, [clientDetailData?.profile, currentLocale]);

  return isLoading ? (
    <ClientOverviewCardLoader variant="avatar" />
  ) : (
    <section className="w-full px-4 bg-white box-border flex flex-col gap-4">
      <section className="flex items-start gap-3 mb-1">
        <Avatar clientName={clientName} />
        <div className="grow">
          <section className="max-w-56 shrink text-xl font-semibold truncate">
            {clientName}
          </section>
          <div className="text-xs text-gray-600">
            {clientDetailData?.profile?.createdAt
              ? t('MATCHED_ON_DATE', {
                  date: getDisplayDate({
                    locale: currentLocale,
                    translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
                    format: {
                      ja: 'MM,DD,YYYY,dddd',
                      en: ClientMatchedOnDateFormat,
                    },
                    selectedDate: moment(clientDetailData?.profile?.createdAt),
                    translate: t,
                  }),
                })
              : '-'}
          </div>
        </div>
        <Link to={`/clients/${serviceType}/${encodedClientId}`}>
          <Button variant="secondary" className="p-2.5">
            <LinkIcon className="w-5 h-5 text-gray-600" />
          </Button>
        </Link>
      </section>
      <section className="grid grid-cols-12 gap-3">
        {Object.entries(ClientPersonalInfo).map(([key, obj]) => (
          <div
            key={`userinfo-${key}`}
            className={twMerge(
              'text-xs col-span-3',
              'className' in obj && obj.className,
            )}
          >
            <div className="font-medium">{t(key)}</div>
            <div className="text-gray-600">{obj.value}</div>
          </div>
        ))}
      </section>
    </section>
  );
}
