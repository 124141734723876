/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react';
import ContentLoader from 'react-content-loader';

export function BoxLoader({
  height = 100,
  width = '100%',
  ...restProps
}: SVGProps<SVGRectElement>) {
  return (
    <ContentLoader
      speed={2}
      height={height}
      width={width}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect {...restProps} width="100%" height="100%" />
    </ContentLoader>
  );
}
