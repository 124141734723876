import {
  CaseNoteFormType,
  CaseNoteQuestionTypes,
} from '../../../shared/constant/CoachingCaseNotes';

export const CaseNoteTypesWhereSwitcherShouldBeShown = [
  CaseNoteFormType.V1,
  CaseNoteFormType.V3,
  CaseNoteFormType.V4,
] as const;

export const CaseNoteFieldsToDiscardInSwitch = {
  [CaseNoteFormType.V3]: [
    CaseNoteQuestionTypes.DetailsOfPresentingIssues,
    CaseNoteQuestionTypes.HistoryOfPI,
    CaseNoteQuestionTypes.MedicalAndMentalHistory,
    CaseNoteQuestionTypes.FamilyAndRelationshipHistory,
    CaseNoteQuestionTypes.ChildhoodExperiences,
    CaseNoteQuestionTypes.SocialHistory,
    CaseNoteQuestionTypes.WorkAndEducationalHistory,
    CaseNoteQuestionTypes.SubstanceUseHistory,
    CaseNoteQuestionTypes.CurrentCopingHabits,
    CaseNoteQuestionTypes.Others,
    CaseNoteQuestionTypes.ClientGoalsForTherapy,
  ],
  [CaseNoteFormType.V4]: [
    CaseNoteQuestionTypes.Observation,
    CaseNoteQuestionTypes.SessionNotes,
    CaseNoteQuestionTypes.InterventionsAndModality,
    CaseNoteQuestionTypes.ResponseToInterventionTreatment,
  ],
};

// Temporary readable fix as each form type has different conditions on what to disable
export const CaseNoteOptionsToDisableInSwitch = {
  [CaseNoteFormType.V1]: [
    CaseNoteFormType.V3,
    CaseNoteFormType.V4,
    CaseNoteFormType.MINIMAL,
  ],
  [CaseNoteFormType.V3]: [CaseNoteFormType.V1, CaseNoteFormType.MINIMAL],
  [CaseNoteFormType.V4]: [CaseNoteFormType.V1, CaseNoteFormType.MINIMAL],
} satisfies {
  [destinationFormType: string]: string[];
};

export const CaseNoteFilterEventValues = {
  [CaseNoteFormType.V3]: 'intake_form',
  [CaseNoteFormType.V4]: 'clinical',
  [CaseNoteFormType.V1]: 'coaching',
  [CaseNoteFormType.MINIMAL]: 'basic',
};
