import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ClientDetailsContext from '../../../../../../v2/view/screens/ClientDetails/context/ClientDetailsContext';
import { BookSessionComponent } from '../../../../../../v2/view/app-components/BookSessionComponent/BookSessionComponent';
import Modal from '../../../../../../v2/view/components/Modal';
import { BoxLoader } from '../../../../../../v2/view/components/Loader/BoxLoader';

export function ChatBookSessionModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const { t } = useTranslation();
  const { clientDetailData, clientName, refreshData, isLoading } =
    useContext(ClientDetailsContext);
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={t('SESSION_BOOKING_HEADER')}
      className="w-[55vw]"
    >
      {!clientDetailData || isLoading ? (
        <div className="flex flex-col gap-5">
          <BoxLoader />
          <BoxLoader height="30px" />
          <BoxLoader height='30px' />
          <BoxLoader height="30px" />
        </div>
      ) : (
        <BookSessionComponent
          clientData={{
            name: clientName,
            userId: clientDetailData.profile.userId,
            providerRole: clientDetailData.profile.providerRole,
            credits: clientDetailData.credits,
            defaultSlotsDuration: clientDetailData.defaultSlotsDuration,
          }}
          onConfirm={() => refreshData()}
          handleClose={handleClose}
        />
      )}
    </Modal>
  );
}
