/* eslint-disable */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { ReactComponent as AttachmentIcon } from '../../../../v2/assets/paperclip.svg';
import { ReactComponent as RecordVoiceIcon } from '../../../../v2/assets/microphone-01.svg';
import ChatVoiceRecorder from '../ChatVoiceRecorderV2';
import MessageInputAudioPreview from '../MessageInputAudioPreviewV2';
import { MessageSendType } from '../../../constants/CommonConstants';
import { IMessageSendType } from '../../../data-types/ChatBrokerTypes';
import AttachmentPreview from '../AttachmentPreview';
import { byteToMegaByte } from '../../../utils/helper';
import { Button } from '../../../../v2/view/components/Button';
import useLayout from '../../../../utilities/hooks/useLayout';

const messageInputBoxConfig = `
  min-h-[96px] 
  box-border 
  p-3.5  
  flex 
  flex-col 
  justify-start 
  relative 
  border
  rounded-md 
  transition-all 
  border-gray-300 
  focus-within:border-blueLight-300 
  focus-within:shadow-[0px_0px_0px_4px_#B2DDFF] 
  box-border
`;
const textAreaConfig = `
  w-full peer resize-none focus:border-none focus:outline-none min-h-6
`;

interface IMessageInputBoxProps {
  value: string;
  recordedVoice: Blob | null;
  onSendMessage: () => void;
  onTextValueChange: (value: string) => void;
  onAttachmentChange: (value: IMessageSendType | null) => void;
  onTypedTextKeyPress: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onRecordAudio: (recordedAudio: IMessageSendType) => void;
  file: IMessageSendType | null;
  textAreaRef?: React.MutableRefObject<null>;
}

enum MessageInputBoxMode {
  text = 'text',
  voice = 'voice',
}

function MessageInputBox({
  value,
  recordedVoice,
  file,
  onTextValueChange,
  onAttachmentChange,
  onSendMessage,
  onTypedTextKeyPress,
  onRecordAudio,
  textAreaRef,
}: Readonly<IMessageInputBoxProps>) {
  const { t } = useTranslation();
  const {chatWindowVisible} = useLayout();
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const [recordedAudio, setRecordedAudio] = React.useState<Blob | null>(null);
  const [messageInputMode, setMessageInputMode] =
    React.useState<MessageInputBoxMode>(MessageInputBoxMode.text);
  const fileTypeRegex: RegExp = /^(application\/pdf|image\/.*)$/;
  const onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: newValue } = event.target;
    if (newValue !== '\n') {
      onTextValueChange?.(newValue);
    }
    if (textAreaRef?.current && value.length) {
      // @ts-ignore
      // eslint-disable-next-line
      textAreaRef.current.style.height = '0px';
      // @ts-ignore
      const { scrollHeight } = textAreaRef.current;
      // @ts-ignore
      // eslint-disable-next-line
      textAreaRef.current.style.height = `${Math.min(scrollHeight, chatWindowVisible ? 100 : 196)}px`;
    }
  };

  const onSelectedFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event?.target?.files?.length &&
      !fileTypeRegex.test(event.target.files[0].type)
    ) {
      toast.error(t('UNSUPPORTED_FILE_ERROR'));
      return;
    }
    if (
      event?.target?.files?.length &&
      byteToMegaByte(event?.target?.files?.[0]?.size) <= 5
    ) {
      const attachmentFile = event.target.files[0];
      const fileType =
        attachmentFile.type === 'application/pdf'
          ? MessageSendType.PDF
          : MessageSendType.IMAGE;
      const data: IMessageSendType = {
        data: attachmentFile,
        type: fileType,
      };
      onAttachmentChange(data);
    } else {
      toast.error(t('ERROR_STATE_SIZE_HEADER'), {
        description: t('ERROR_STATE_SIZE_SUBTEXT'),
      });
    }
  };

  const onVoiceRecordingCancel = () => {
    setMessageInputMode(MessageInputBoxMode.text);
  };

  const onVoiceRecordingEnd = (voice: Blob) => {
    if (voice) {
      const recordedDataClone = voice;
      setRecordedAudio(recordedDataClone);
      const data = {
        data: recordedDataClone,
        type: MessageSendType.AUDIO,
      };
      onRecordAudio(data);
    }
  };

  React.useEffect(() => {
    // Synchronize state of the outer audio updates with inner state.
    if (!recordedVoice) {
      setRecordedAudio(null);
    }
  }, [recordedVoice]);

  const sendBtnDisable = React.useMemo(
    () => !value && !file && !recordedVoice,
    [value, file, recordedVoice],
  );
  const onSendBtn = () => {
    if (sendBtnDisable) {
      return;
    }
    // @ts-ignore
    // eslint-disable-next-line
    textAreaRef.current.style.height = '40px';
    onSendMessage();
  };

  return (
    <section className='w-full p-5'>
      <section className={messageInputBoxConfig}>
        {recordedAudio && (
          <MessageInputAudioPreview
            onClose={() => setRecordedAudio(null)}
            audioStream={recordedAudio}
            className='w-1/2'
          />
        )}
        {file && (
          <AttachmentPreview
            attachmentFile={file}
            onClose={() => onAttachmentChange(null)}
          />
        )}
        <section
          className="w-full"
          onKeyDown={onTypedTextKeyPress}
          role="button"
          tabIndex={0}
        >
          <section className="relative flex flex-col gap-3">
            {messageInputMode === MessageInputBoxMode.text && (
              <section>
                <textarea
                  ref={textAreaRef}
                  placeholder={t('SEND_MESSAGE_PROMPT')}
                  onChange={onTextChange}
                  value={value}
                  className={textAreaConfig}
                  autoFocus
                  maxLength={900}
                />
                <section className="h-9 flex justify-end items-center gap-1.5">
                  <Button
                    variant="secondary"
                    onClick={() => inputFileRef.current?.click()}
                    className="h-9 w-9 p-[7px]"
                  >
                    <AttachmentIcon />
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setMessageInputMode(MessageInputBoxMode.voice)
                    }
                    className="h-9 w-9 p-[7px]"
                  >
                    <RecordVoiceIcon />
                  </Button>
                  <Button disabled={sendBtnDisable} onClick={onSendBtn}>
                    {t('SEND_CTA')}
                  </Button>
                  <input
                    type="file"
                    accept="image/*, application/pdf"
                    onChange={(e) => onSelectedFileChange(e)}
                    ref={inputFileRef}
                    onClick={(e) => {
                      // reset file upload value to trigger change on same file upload next time
                      const element = e.target as HTMLInputElement;
                      element.value = '';
                    }}
                    style={{ display: 'none' }}
                  />
                </section>
              </section>
            )}
            {messageInputMode === MessageInputBoxMode.voice && (
              <ChatVoiceRecorder
                onRecordingCancel={onVoiceRecordingCancel}
                onRecordingEnd={onVoiceRecordingEnd}
              />
            )}
          </section>
        </section>
      </section>
    </section>
  );
}

export default MessageInputBox;
