import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import useMediaPreview from '../../../../utilities/hooks/useMediaPreview';
import Loader from '../../../../view/components/Loader';
import { CssSize } from '../../../../view/components/BaseCard/BaseCard.d';
import TextMessageItem from '../TextMessageItemV2';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';

interface IImageMessageItemProps {
  imageUrl: string;
  disabled?: boolean;
  height?: CssSize;
  width?: CssSize;
  body?: string;
  source?: string;
  containerClassname?: string;
}

function ImageMessageItem({
  imageUrl,
  disabled,
  height,
  width,
  body,
  source,
  containerClassname,
}: Readonly<IImageMessageItemProps>) {
  const { track } = useTracking();
  const { t } = useTranslation();

  const [imageFullyLoaded, setImageFullyLoaded] =
    React.useState<boolean>(false);

  const imageRef = React.useRef<HTMLImageElement>(null);
  const { showPreview } = useMediaPreview();

  const onImageLoaded = () => {
    setImageFullyLoaded(true);
  };

  const handleChatImageClick = () => {
    if (!disabled) {
      showPreview(imageUrl);
      track(EventNames.messages, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.clickAttachment,
        eventLabel: 'image',
      });
    }
  };
  const onError = () => {
    setImageFullyLoaded(true);
    toast.error(t('ERROR_STATE_INTERNET_HEADER'), {
      description: t('ERROR_STATE_INTERNET_SUBTEXT'),
    });
  };

  const loaderDimension = {
    height: height || '200px',
    width: width || '200px',
  } as unknown as DOMRect;
  return (
    <div
      className={twMerge(
        'w-full h-auto flex flex-col justify-center items-center relative cursor-pointer',
        containerClassname,
      )}
    >
      {!imageFullyLoaded && (
        <Loader
          useFullPage={false}
          withBackdrop={false}
          dimension={loaderDimension}
        />
      )}
      <img
        src={imageUrl}
        className="w-full max-h-[220px] object-contain rounded"
        alt="message"
        ref={imageRef}
        onLoad={onImageLoaded}
        onError={onError}
        style={
          imageFullyLoaded
            ? { visibility: 'visible', height, width }
            : { visibility: 'hidden' }
        }
        onClick={handleChatImageClick}
      />
      {!!body && source && (
        <section className="w-full pt-3">
          <TextMessageItem source={source} message={body} />
        </section>
      )}
    </div>
  );
}

export default ImageMessageItem;
