import moment from 'moment';
import { ISingleSessionResp } from '../hooks/useInPersonInfo/types';
import { VideoRoomSDKs } from '../../shared/constant/App';
import {
  InPersonBannerStates,
  SessionStatus,
} from '../../shared/constant/Common';

export function getInPersonMeetingState({
  singleSessionData,
  enable,
}: {
  singleSessionData?: Partial<ISingleSessionResp>;
  enable: boolean;
}) {
  if (
    moment
      .unix(Number(singleSessionData?.scheduledStartTime))
      .diff(moment(), 'minutes') > 5
  ) {
    return InPersonBannerStates.unknown;
  }
  if (
    singleSessionData &&
    enable &&
    singleSessionData?.type === VideoRoomSDKs.f2f
  ) {
    if (singleSessionData?.status === SessionStatus.inprogress) {
      return InPersonBannerStates.stop;
    }
    if (singleSessionData?.status === SessionStatus.completed) {
      return InPersonBannerStates.completed;
    }
    if (
      moment().isAfter(
        moment.unix(Number(singleSessionData?.scheduledEndTime)),
      ) &&
      singleSessionData?.status === SessionStatus.scheduled
    ) {
      return InPersonBannerStates.noAction;
    }
    if (
      moment().diff(
        moment.unix(Number(singleSessionData?.scheduledStartTime)),
        'minutes',
      ) >= 45 &&
      singleSessionData?.status === SessionStatus.scheduled
    ) {
      return InPersonBannerStates.noShow;
    }
    if (
      moment
        .unix(Number(singleSessionData?.scheduledStartTime))
        .diff(moment(), 'minutes') <= 5 &&
      singleSessionData?.status === SessionStatus.scheduled
    ) {
      return InPersonBannerStates.start;
    }
  }
  return InPersonBannerStates.unknown;
}

export function getOnsiteMeetingState(
  singleSessionData?: Partial<ISingleSessionResp>,
  extraData?: { isAttemptingNoShow: boolean },
) {
  if (extraData?.isAttemptingNoShow) {
    return InPersonBannerStates.noShow;
  }
  if (singleSessionData?.status === SessionStatus.inprogress) {
    return InPersonBannerStates.stop;
  }
  if (singleSessionData?.status === SessionStatus.completed) {
    return InPersonBannerStates.completed;
  }
  if (
    moment().isAfter(
      moment.unix(Number(singleSessionData?.scheduledEndTime)),
    ) &&
    singleSessionData?.status === SessionStatus.scheduled
  ) {
    return InPersonBannerStates.noAction;
  }
  if (
    moment
      .unix(Number(singleSessionData?.scheduledStartTime))
      .diff(moment(), 'minutes') <= 5 &&
    singleSessionData?.status === SessionStatus.scheduled
  ) {
    return InPersonBannerStates.start;
  }

  return InPersonBannerStates.unknown;
}
