import { useMutation, useQuery } from 'react-query';
import {
  addProviderLeaves,
  addProviderOverride,
  getProviderAvailabilities,
  getProviderOverrides,
  getProviderServicesAndClinics,
  removeProviderLeaves,
} from '../../../../shared/service/API/availability';
import {
  IAddProviderLeavesReqType,
  IAddProviderOverrideReqType,
  IProviderAvailabilityOverrides,
  IProviderRawAvailability,
  IRemoveProviderLeavesReqType,
  ServicesReturnType,
} from '../../../../shared/types/Availability';

export function useProviderAvailabilities() {
  return useQuery<IProviderRawAvailability>(
    ['provider-v2-availabilities'],
    () => getProviderAvailabilities(),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useProviderServicesAndClinics<
  T extends boolean | undefined = undefined,
>(params?: { withCalendar?: T }) {
  return useQuery<ServicesReturnType<T>>(
    ['provider-v2-services-and-clinics', params?.withCalendar],
    () => getProviderServicesAndClinics(params?.withCalendar),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useProviderOverrides(fromDate: string, toDate: string) {
  return useQuery<IProviderAvailabilityOverrides>(
    ['provider-v2-overrides', fromDate, toDate],
    () => getProviderOverrides(fromDate, toDate),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 600000, // 10 minutes
      refetchIntervalInBackground: true,
    },
  );
}

export function useAddOverride(
  handleSuccess: () => void,
  handleError: () => void,
) {
  return useMutation(
    (payload: IAddProviderOverrideReqType[]) =>
      addProviderOverride({ overrides: payload }),
    { onSuccess: handleSuccess, onError: handleError },
  );
}

export function useRemoveLeave(
  handleSuccess: () => void,
  handleError: () => void,
) {
  return useMutation(
    (payload: IRemoveProviderLeavesReqType) => removeProviderLeaves(payload),
    { onSuccess: handleSuccess, onError: handleError },
  );
}

export function useAddLeave(
  handleSuccess: () => void,
  handleError: () => void,
) {
  return useMutation(
    (payload: IAddProviderLeavesReqType) => addProviderLeaves(payload),
    { onSuccess: handleSuccess, onError: handleError },
  );
}
