import ContentLoader from 'react-content-loader';
import { twMerge } from 'tailwind-merge';

interface IProps {
  className?: string;
  count?: number;
}
export function ChatBoxLoader({ className }: Readonly<IProps>) {
  return (
    <section
      className={twMerge(
        'w-1/2 bg-white p-4 m-4 box-border flex flex-col gap-3',
        'items-start justify-center border border-solid border-gray-200',
        className,
      )}
    >
      <ContentLoader
        speed={2}
        height={100}
        width="100%"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="10" rx="10" ry="10" width="100%" height="15" />
        <rect x="0" y="30" rx="10" ry="10" width="80%" height="15" />
        <rect x="0" y="50" rx="10" ry="10" width="100%" height="15" />
        <rect x="0" y="70" rx="10" ry="10" width="80%" height="15" />
      </ContentLoader>
    </section>
  );
}

export default function LiveChatLoader({ className }: Readonly<IProps>) {
  return (
    <section
      className={twMerge(
        'w-full',
        'overflow-y-auto',
        'relative',
        'h-[calc(100vh-60px)]',
        className,
      )}
    >
      <section
        className={twMerge(
          'w-full bg-white p-4 box-border flex flex-col gap-3',
          'items-start justify-center border-b border-solid border-gray-200',
        )}
      >
        <ContentLoader
          speed={2}
          height={50}
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="10" rx="3" ry="3" width="15%" height="30" />
        </ContentLoader>
      </section>
      <ChatBoxLoader className="float-right" />
      <ChatBoxLoader className="float-left" />
      <section
        className={twMerge(
          'w-full bg-white p-4 box-border flex flex-col gap-3',
          'items-start justify-center border border-solid border-gray-200',
          'absolute bottom-0',
        )}
      >
        <ContentLoader
          speed={2}
          height={100}
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="10" rx="3" ry="3" width="100%" height="80" />
        </ContentLoader>
      </section>
    </section>
  );
}

export function ChatListItemLoader({ className, count = 1 }: Readonly<IProps>) {
  return (
    <section className="flex flex-col gap-3">
      {Array.from({ length: count }).map((item:any) => (
        <section
          className={twMerge(
            'max-w-[360px] rounded-lg bg-white p-3 box-border flex flex-col',
            'items-start justify-between border border-solid border-gray-200',
            className,
          )}
          key={item}
        >
          <ContentLoader
            speed={2}
            height={60}
            width="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="25" cy="30" r="20" />
            <rect x="50" y="10" rx="10" ry="10" width="40%" height="15" />
            <rect x="50" y="35" rx="10" ry="10" width="70%" height="15" />
          </ContentLoader>
        </section>
      ))}
    </section>
  );
}
