// disable eslint for this file
import io, { Socket } from 'socket.io-client';
import Config from '../../shared/Config';
import { getItemFromLocalStorage } from '../../utilities/common/Storage';
import { StorageItems } from '../../shared/constant/App';
import { MessageContentTypes } from '../constants/CommonConstants';
import { logErrorWithAdditionalInfoToSentry } from '../../utilities/monitoring';

export interface SocketMessage {
  conversationId: string;
  id: string;
  message: string;
  senderId: number;
  attachments: [{ content: string; type: MessageContentTypes }];
}

export default class IrisSocket {
  socket: Socket | undefined;

  constructor() {
    const authToken: any = getItemFromLocalStorage(
      StorageItems.USER_INFO,
      'object',
    );
    this.socket = io(Config.webSocket, {
      transports: ['websocket'],
      auth: { token: authToken.token },
    });
    // Listen for socket errors and log them to Sentry
    this.socket.on('connect_error', (error) => {
      logErrorWithAdditionalInfoToSentry(error as Error, {
        context: 'Connection Error',
        additionalInfo: {
          message: (error as Error).message,
          description: (error as any).description,
          context: (error as any).context,
        },
      });
      
    });

    this.socket.on('error', (error) => {
      logErrorWithAdditionalInfoToSentry(error as Error, {
        context: 'General Error',
        additionalInfo: {
          message: (error as Error).message,
        },
      });
    });
  }

  public isSocketConnected() {
    if (this.socket !== undefined) {
      return true;
    }
    return false;
  }

  public addSocketListener = (listenerName: string, callbackFn: Function) => {
    this.socket!.on(listenerName, (message: SocketMessage) => {
      callbackFn(message);
    });
  };

  public emitToServer = (name: string, data: any) => {
    try {
      this.socket!.emit(name, data);
    } catch (error) {
      logErrorWithAdditionalInfoToSentry(error as Error, {
        context: 'Emit Error',
        additionalInfo: {
          eventName: name,
          eventData: data,
        },
      });
    }
  };
}
