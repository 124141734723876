import * as React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as CloseIcon } from '../../../../assets/close-white.svg';
import useMediaPreview from '../../../../utilities/hooks/useMediaPreview';
import { Button } from '../../../../v2/view/components/Button';

const mediaViewerConfig =
  'h-screen w-screen fixed top-0 right-0 z-[11] flex justify-center items-center bg-black/75';

interface IMediaViewerProps {
  mediaUrl: string;
}

function MediaViewer({ mediaUrl }: Readonly<IMediaViewerProps>) {
  const { showPreview } = useMediaPreview();
  return (
    <motion.div
      className={mediaViewerConfig}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Button
        variant="ghost"
        onClick={() => showPreview(null)}
        className="absolute top-12 right-12 cursor-pointer w-12"
      >
        <CloseIcon />
      </Button>
      <img
        className="max-h-[85%] max-w-[90%] object-contain"
        src={mediaUrl}
        alt="media file"
      />
    </motion.div>
  );
}

export default MediaViewer;
