import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { riskColorVariants } from '../../../shared/constant/Colors';
import { ClientProfileDateFormat } from '../../../utilities/common/Date';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import AddRiskLevelModal from './AddRiskLevelModal';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
import ClientDetailsContext from './context/ClientDetailsContext';
import { useClientRiskLevel } from './hooks/UseClientRiskLevel';
import RiskEmptyState from './RiskEmptyState';
import RiskLabel from './RiskLabels';
import { ClientStatus } from '../../../shared/constant/Clients';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import useTracking from '../../../../utilities/hooks/useTracking';
import { getDisplayDate } from '../../../../utilities/common/Date';
import { getCurrentLocale } from '../../../../i18n';

export default function UserRiskLevelCard() {
  const [showRiskLevelModal, setShowRiskLevelModal] = useState<boolean>(false);

  const { decodedClientId, serviceType, isLoading, clientDetailData, context } =
    useContext(ClientDetailsContext);

  const { t } = useTranslation();
  const { track } = useTracking();
  const currentLocale = getCurrentLocale();

  const {
    data: clientRiskAssessments,
    isLoading: isRiskLoading,
    refetch: refetchRisk,
  } = useClientRiskLevel(decodedClientId, serviceType);

  // picking recent note
  const existingRisk = clientRiskAssessments?.[0];

  const isLocked =
    clientDetailData?.profile?.status === ClientStatus.UNMATCHED_CLIENTS;

  const onRiskLevelClick = () => {
    track(EventNames.clientView, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.riskLevel,
      eventLabel: 'risk_level',
    });
    setShowRiskLevelModal(true);
  };

  if (isLoading || isRiskLoading) {
    return <ClientOverviewCardLoader variant="card" />;
  }

  return (
    <>
      <BasicCardTemplate
        heading={t('RISK_LEVEL_HEADER')}
        tooltipContent={t('RISK_LEVEL_TOOLTIP')}
        showToolTip
        className={context === 'messages' ? 'border-none shadow-none rounded-none' : ''}
      >
        {!existingRisk ? (
          <RiskEmptyState
            onClick={() => onRiskLevelClick()}
            isLocked={isLocked}
          />
        ) : (
          <RiskLabel
            riskLevel={t(riskColorVariants?.[existingRisk?.risk]?.label)}
            subText={getDisplayDate({
              locale: currentLocale,
              translatorResource: { ja: 'DISPLAY_DATE_WITH_TIME' },
              format: { ja: 'MM,DD,YYYY,HH,mm', en: ClientProfileDateFormat },
              selectedDate: moment(existingRisk?.updatedAt),
              translate: t,
            })}
            variant={existingRisk?.risk ?? ''}
            onClick={() => onRiskLevelClick()}
            isLocked={isLocked}
          />
        )}
      </BasicCardTemplate>
      <AddRiskLevelModal
        open={showRiskLevelModal}
        handleClose={() => setShowRiskLevelModal(false)}
        handleSuccess={() => refetchRisk()}
        existingRiskLevel={existingRisk}
      />
    </>
  );
}
