import { t } from 'i18next';
import moment from 'moment';
import { VideoRoomSDKs } from '../../../shared/constant/App';
import { MeetingSDKType } from '../../../shared/types/App';
import { ProviderRole } from '../../../shared/constant/Common';
import {
  InPersonBannerStates,
  MeetingStatus,
} from '../../shared/constant/Session';
import { isProduction } from '../../../utilities/common/Path';

export const getVideoTagStyle = (
  providerRole: string,
  sessionType: MeetingSDKType,
) => {
  if (sessionType === VideoRoomSDKs.f2f) {
    if (providerRole === ProviderRole.ONSITE) {
      return { name: t('ONSITE'), backgroundColor: '#f9ef9680' };
    }

    return { name: t('INPERSON'), backgroundColor: '#d1e6fa' };
  }
  return { name: t('VIRTUAL'), backgroundColor: '#dbbfe5' };
};

export function getInPersonMeetingState({
  singleSessionData,
  enable,
}: {
  singleSessionData?: {
    status: string;
    role: string;
    scheduledEndTime: string;
    scheduledStartTime: string;
  };
  enable: boolean;
}) {
  if (
    moment
      .unix(Number(singleSessionData?.scheduledStartTime))
      .diff(moment(), 'minutes') > 5 &&
    singleSessionData?.status === MeetingStatus.scheduled
  ) {
    return InPersonBannerStates.notReadyToStart;
  }
  if (
    singleSessionData &&
    enable &&
    singleSessionData?.role === ProviderRole.CAREOFFSITE
  ) {
    if (
      [MeetingStatus.inprogress, MeetingStatus.empty].some(
        (status) => status === singleSessionData?.status,
      )
    ) {
      return InPersonBannerStates.stop;
    }
    if (singleSessionData?.status === MeetingStatus.completed) {
      return InPersonBannerStates.completed;
    }
    if (
      moment().isAfter(
        moment.unix(Number(singleSessionData?.scheduledEndTime)),
      ) &&
      singleSessionData?.status === MeetingStatus.scheduled
    ) {
      return InPersonBannerStates.noAction;
    }
    if (
      moment().diff(
        moment.unix(Number(singleSessionData?.scheduledStartTime)),
        'minutes',
      ) >= 45 &&
      singleSessionData?.status === MeetingStatus.scheduled
    ) {
      return InPersonBannerStates.noShow;
    }
    if (
      moment
        .unix(Number(singleSessionData?.scheduledStartTime))
        .diff(moment(), 'minutes') <= 5 &&
      singleSessionData?.status === MeetingStatus.scheduled
    ) {
      return InPersonBannerStates.start;
    }
  }
  return InPersonBannerStates.unknown;
}

export function getOnsiteMeetingState(
  singleSessionData?: {
    status: string;
    role: string;
    scheduledEndTime: string;
    scheduledStartTime: string;
  },
  extraData?: { isAttemptingNoShow: boolean },
) {
  if (singleSessionData && singleSessionData?.role === ProviderRole.ONSITE) {
    if (singleSessionData?.status === MeetingStatus.scheduled) {
      if (
        moment
          .unix(Number(singleSessionData?.scheduledStartTime))
          .diff(moment(), 'minutes') > 5
      ) {
        return InPersonBannerStates.notReadyToStart;
      }
      if (
        moment
          .unix(Number(singleSessionData?.scheduledStartTime))
          .diff(moment(), 'minutes') <= 5
      ) {
        return InPersonBannerStates.start;
      }
    }
    if (extraData?.isAttemptingNoShow) {
      return InPersonBannerStates.noShow;
    }
    if (
      [MeetingStatus.inprogress, MeetingStatus.empty].some(
        (status) => status === singleSessionData?.status,
      )
    ) {
      return InPersonBannerStates.stop;
    }
    if (singleSessionData?.status === MeetingStatus.completed) {
      return InPersonBannerStates.completed;
    }
    if (
      moment().isAfter(
        moment.unix(Number(singleSessionData?.scheduledEndTime)),
      ) &&
      singleSessionData?.status === MeetingStatus.scheduled
    ) {
      return InPersonBannerStates.noAction;
    }
  }
  return InPersonBannerStates.unknown;
}

const DailyCoRoomUrls = {
  dev: 'https://intellect-stage.daily.co/',
  prod: 'https://intellect.daily.co/',
};

export function getDailyCoUrlFromRoomId(roomId: string) {
  if (isProduction()) {
    return DailyCoRoomUrls.prod + roomId;
  }

  return DailyCoRoomUrls.dev + roomId;
}
