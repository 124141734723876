/**
 * Intake - very first session
 *
 * Followup - all other sessions after the intake happened
 * */
export enum CaseNoteFormType {
  /** Old common form */
  v0 = '0',
  /** Coaching */
  V1 = '1',
  /** Care intake outside the session */
  V3 = '3',
  /** Care followup outside the session */
  V4 = '4',
  /** Coaching inside the session */
  V5 = '5',
  /** Care intake inside the session */
  V6 = '6',
  /** Care followup inside the session */
  V7 = '7',
  /** Minimal form to be added in the future */
  MINIMAL = '8',
}

// type in questions
export enum CaseNoteQuestionTypes {
  ClientState = 1,
  PresentingTopic = 2,
  Techniques = 3,
  OutcomeAndProgress = 4,
  FollowUp = 5,
  Other = 6,
  PrimaryGoal = 7,
  SecondaryGoal = 8,
  RiskAssesment = 9,
  SuicidalAssesment = 10,
  SessionNotes = 11,
  InterventionsAndModality = 12,
  FormulationAndTreatment = 13,
  ProgressTowardsGoals = 14,
  Recommendations = 15,
  FollowUpPlan = 16,
  AdditonalSessionRecommended = 17,
  SharedFollowUpPlan = 18,
  HistoryOfPI = 19,
  MedicalAndMentalHistory = 20,
  FamilyAndRelationshipHistory = 21,
  ChildhoodExperiences = 22,
  SocialHistory = 23,
  WorkAndEducationalHistory = 24,
  SubstanceUseHistory = 25,
  CurrentCopingHabits = 26,
  Others = 27,
  ClientGoalsForTherapy = 28,
  HaveSharedFollowUpPlanWithClient = 29,
  Observation = 30,
  ResponseToInterventionTreatment = 31,
  DetailsOfPresentingIssues = 32,
}

export enum RiskAssesment {
  NoRisk = 1,
  LowRisk = 2,
  MediumRisk = 3,
  HighRiskNonCritical = 5,
  HighRiskCritical = 4,
  NotAssessed = 6,
}

export enum ProgressTowardsGoals {
  Exploring = 1,
  SteadyProgress = 2,
  Reevalutaion = 3,
  MaintenanceGoalAttained = 4,
}

export enum Recommendations {
  NeedsAdditionalSupport = 1,
  Referral = 2,
  LifeStyleChanges = 3,
  NoRecommendations = 4,
}

export enum SharedFollowUpPlan {
  Yes = 1,
  No = 2,
}
export enum InterventionsAndModality {
  ICFCoaching = 1,
  CBT = 2,
  BehavioralExposureTherapy = 3,
  DBT = 4,
  MBCT = 5,
  ACT = 6,
  CFT = 7,
  SFT = 8,
  PsychodynamicTherapy = 9,
  SystemicFamilyTherapy = 10,
  IPT = 11,
  SFBT = 12,
  NarrativeTherapy = 13,
  MotivationalInterviewing = 14,
  IntegrativeEclectic = 15,
  Others = 16,
}

export enum SuicidalAssesment {
  SuicidalThoughts = 1,
  SupportSystem = 2,
  SelfHarmHistory = 3,
  SafetyAndRiskAssesment = 4,
}

export enum CareIntakePart {
  Part1 = 1,
  Part2 = 2,
}

export enum ResponseToTreatment {
  DemonstratingImprovement = 1,
  ProgressingSlow = 2,
  NoSignificantImprovement = 3,
  ExperiencingWorsening = 4,
  FluctuatingProgress = 5,
  Formulating = 6,
}

export const SOSNumbers = ['+60 126605674', '+65 88125992'];

export const ICaseNotesFollowUpMap = {
  riskAssessment: CaseNoteQuestionTypes.RiskAssesment,
  primaryIssue: CaseNoteQuestionTypes.PrimaryGoal,
  secondaryIssue: CaseNoteQuestionTypes.SecondaryGoal,
  observation: CaseNoteQuestionTypes.Observation,
  sessionNotes: CaseNoteQuestionTypes.SessionNotes,
  interventionsModality: CaseNoteQuestionTypes.InterventionsAndModality,
  followUpPlanDesc: CaseNoteQuestionTypes.FollowUpPlan,
  additionalSessions: CaseNoteQuestionTypes.AdditonalSessionRecommended,
  response: CaseNoteQuestionTypes.ResponseToInterventionTreatment,
  recommendations: CaseNoteQuestionTypes.Recommendations,
  suicidalAssessment: CaseNoteQuestionTypes.SuicidalAssesment,
  treatmentPlan: CaseNoteQuestionTypes.FormulationAndTreatment,
  hasSharedFollowup: CaseNoteQuestionTypes.SharedFollowUpPlan,
};

export const ICaseNotesIntakeMap = {
  presentingIssuesHistory: CaseNoteQuestionTypes.HistoryOfPI,
  medicalMentalHistory: CaseNoteQuestionTypes.MedicalAndMentalHistory,
  familyAndRelationshipHistory:
    CaseNoteQuestionTypes.FamilyAndRelationshipHistory,
  childhoodExperiences: CaseNoteQuestionTypes.ChildhoodExperiences,
  socialHistory: CaseNoteQuestionTypes.SocialHistory,
  workAndEducationalHistory: CaseNoteQuestionTypes.WorkAndEducationalHistory,
  substanceUseHistory: CaseNoteQuestionTypes.SubstanceUseHistory,
  currentCopingHabits: CaseNoteQuestionTypes.CurrentCopingHabits,
  others: CaseNoteQuestionTypes.Others,
  clientGoalsForTherapy: CaseNoteQuestionTypes.ClientGoalsForTherapy,
  formulationAndTreatment: CaseNoteQuestionTypes.FormulationAndTreatment,
  detailsOfPresentingIssues: CaseNoteQuestionTypes.DetailsOfPresentingIssues,
  additonalSessionRecommended:
    CaseNoteQuestionTypes.AdditonalSessionRecommended,
  followUpPlan: CaseNoteQuestionTypes.FollowUpPlan,
  primaryGoal: CaseNoteQuestionTypes.PrimaryGoal,
  recommendations: CaseNoteQuestionTypes.Recommendations,
  riskAssessment: CaseNoteQuestionTypes.RiskAssesment,
  secondaryGoal: CaseNoteQuestionTypes.SecondaryGoal,
  sessionNotes: CaseNoteQuestionTypes.SessionNotes,
  sharedFollowUpPlan: CaseNoteQuestionTypes.SharedFollowUpPlan,
  suicidalAssessment: CaseNoteQuestionTypes.SuicidalAssesment,
};

export const ICaseNotesCoachingMap = {
  riskAssessment: CaseNoteQuestionTypes.RiskAssesment,
  primaryGoal: CaseNoteQuestionTypes.PrimaryGoal,
  secondaryGoal: CaseNoteQuestionTypes.SecondaryGoal,
  sessionNotes: CaseNoteQuestionTypes.SessionNotes,
  interventionsAndModality: CaseNoteQuestionTypes.InterventionsAndModality,
  progressTowardsGoals: CaseNoteQuestionTypes.ProgressTowardsGoals,
  recommendations: CaseNoteQuestionTypes.Recommendations,
  sharedFollowUpPlan: CaseNoteQuestionTypes.SharedFollowUpPlan,
  followUpPlan: CaseNoteQuestionTypes.FollowUpPlan,
  additonalSessionRecommended:
    CaseNoteQuestionTypes.AdditonalSessionRecommended,
  suicidalAssessment: CaseNoteQuestionTypes.SuicidalAssesment,
};

// export enum CareCaseNotesFrom {
//   ScheduledSessions = 'scheduled_sessions',
//   ClientView = 'client_view',
//   Meeting = 'meeting',
// }
