import React from 'react';

interface IUseAudioRecorderReturns {
  recordingStarted: boolean;
  recordingPaused: boolean;
  audioStream: MediaStream | null;
  recorderInstance: MediaRecorder | null;
  audioBlob: Blob | null;
  audioUrl: string | null;
  duration: string;
  startRecording: () => Promise<void>;
  stopRecording: () => void;
  pauseRecording: () => void;
}

function useAudioRecorder(): IUseAudioRecorderReturns {
  const [recorderState, setRecorderState] =
    React.useState<IUseAudioRecorderReturns>({
      recordingStarted: false,
      recordingPaused: false,
      audioStream: null,
      recorderInstance: null,
      audioBlob: null,
      audioUrl: null,
      duration: '00:00',
      startRecording: async () => {}, // Placeholder empty function
      stopRecording: () => {}, // Placeholder empty function
      pauseRecording: () => {}, // Placeholder empty function
    });

  const elapsedTimeRef = React.useRef(0);
  const timeAtPauseRef = React.useRef(0);

  // Function to start recording
  const startRecording = React.useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setRecorderState((prev) => ({
        ...prev,
        audioStream: stream,
        recordingStarted: true,
        recordingPaused: false,
        duration: '00:00', // Reset duration when starting a new recording
      }));
    } catch (error: any) {
      console.error('Error occurred while getting audio device - ', error);
    }
  }, []);

  // Function to stop recording
  const stopRecording = React.useCallback(() => {
    const { recorderInstance } = recorderState;
    if (recorderInstance && recorderInstance.state !== 'inactive') {
      recorderInstance.stop();
      setRecorderState((prev) => ({
        ...prev,
        recordingStarted: false,
        recordingPaused: false,
        recorderInstance: null,
      }));
      recorderState.audioStream?.getAudioTracks().forEach((track) => track.stop());
    }
  }, [recorderState]);

  // Function to pause or resume recording
  const pauseRecording = React.useCallback(() => {
    const { recorderInstance, recordingStarted, recordingPaused } =
      recorderState;
    if (recorderInstance && recordingStarted) {
      if (recorderInstance.state === 'recording' && !recordingPaused) {
        recorderInstance.pause();
        setRecorderState((prev) => ({ ...prev, recordingPaused: true }));
      } else if (recorderInstance.state === 'paused' && recordingPaused) {
        recorderInstance.resume();
        setRecorderState((prev) => ({ ...prev, recordingPaused: false }));
      }
    }
  }, [recorderState]);

  // Utility function to format time as mm:ss
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds,
    ).padStart(2, '0')}`;
  };

  // Effect to set up the recorder instance and manage the timer
  React.useEffect(() => {
    if (recorderState.audioStream && !recorderState.recorderInstance) {
      const recorderInstance = new MediaRecorder(recorderState.audioStream);
      setRecorderState((prev) => ({
        ...prev,
        recorderInstance,
      }));
    }

    return () => {
      if (recorderState.recorderInstance) {
        recorderState.recorderInstance.stream
          ?.getAudioTracks()
          .forEach((track) => track.stop());
      }
    };
  }, [recorderState.audioStream]);

  // Effect to handle recording state, duration, and timer
  React.useEffect(() => {
    let dataChunks: BlobPart[] = [];
    let timerInterval: NodeJS.Timeout | null = null;

    const { recorderInstance, recordingStarted, recordingPaused } =
      recorderState;

    if (recorderInstance) {
      recorderInstance.ondataavailable = (event: BlobEvent) => {
        dataChunks.push(event.data);
      };

      recorderInstance.onstop = () => {
        const audioBlob = new Blob(dataChunks, { type: 'audio/wav' });
        dataChunks = [];
        setRecorderState((prev) => ({
          ...prev,
          audioBlob,
          audioUrl: window.URL.createObjectURL(audioBlob),
        }));
        if (timerInterval) clearInterval(timerInterval); // Clear the interval when stopping
      };

      // Start recording and timer
      if (
        recorderInstance.state === 'inactive' &&
        recordingStarted &&
        !recordingPaused
      ) {
        recorderInstance.start();

        // Start the timer
        timerInterval = setInterval(() => {
          elapsedTimeRef.current += 1;
          setRecorderState((prev) => ({
            ...prev,
            duration: formatTime(elapsedTimeRef.current), // Format the elapsed time as mm:ss
          }));
        }, 1000); // Update every second
      }

      // Handle pause/resume
      if (recordingPaused) {
        // Track the time when paused
        timeAtPauseRef.current = elapsedTimeRef.current;
        if (timerInterval) clearInterval(timerInterval); // Stop the interval when paused
      }

      if (!recordingPaused && timeAtPauseRef.current > 0) {
        // Resume the timer from where it was paused
        elapsedTimeRef.current = timeAtPauseRef.current;
        timerInterval = setInterval(() => {
          elapsedTimeRef.current += 1;
          setRecorderState((prev) => ({
            ...prev,
            duration: formatTime(elapsedTimeRef.current), // Format the elapsed time as mm:ss
          }));
        }, 1000); // Update every second
      }
    }

    return () => {
      if (timerInterval) clearInterval(timerInterval); // Cleanup the timer interval on unmount
    };
  }, [
    recorderState.recorderInstance,
    recorderState.recordingStarted,
    recorderState.recordingPaused,
  ]);

  return {
    ...recorderState,
    duration: recorderState.duration,
    startRecording,
    stopRecording,
    pauseRecording,
  };
}

export default useAudioRecorder;
