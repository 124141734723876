import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Suspense, lazy } from 'react';
import Login from './view/screens/Login';
import NotFound from './view/screens/NotFound';
import ProtectedRoutes from './ProtectedRoutes';
import VideoRoom from './view/app-components/VideoRoom';
import GetProviderWizard from './view/screens/GetProviderWizard';
import ResetPasswordScreen from './view/screens/ResetPassword';
import CalendarSync from './view/screens/CalendarSync';
import Layout from './view/app-components/Layout/Layout';
import Loader from './view/components/Loader';
import { oldRemovedRoutes } from './v2/shared/constant/App';

const Billing = lazy(() => import('./view/screens/Billing'));
const Assignments = lazy(() => import('./view/screens/Assignments'));
const HelplineRecord = lazy(() => import('./view/screens/HelplineRecord'));
const PersonalInsights = lazy(
  () => import('./view/screens/PersonalInsightsV2'),
);
const BillingDetails = lazy(() => import('./view/screens/BillingDetails'));
const Insights = lazy(() => import('./view/screens/Insights'));
const Settings = lazy(() => import('./view/screens/Settings'));
const CaseNote = lazy(() => import('./view/app-components/CaseNotesV2'));
const CareCaseNotes = lazy(() => import('./view/app-components/CareCaseNotes'));
const Clients = lazy(() => import('./v2/view/screens/Clients'));
const ClientDetailsV2 = lazy(
  () => import('./v2/view/screens/ClientDetails/ClientDetailsV2'),
);
const Availability = lazy(
  () => import('./v2/view/screens/Availability/Availability'),
);

const WorkingHours = lazy(
  () => import('./v2/view/screens/WorkingHours/WorkingHours'),
);

const MessagesWrapper = lazy(
  () => import('./chat-module/view/app-components/MessagesWraper'),
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/reset-password',
        element: <ResetPasswordScreen />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: '/',
            element: <Navigate to="/availability" replace />,
          },
          {
            path: '/clients',
            element: <Clients />,
          },
          {
            path: '/clients/:serviceType/:clientId',
            element: <ClientDetailsV2 />,
          },
          {
            path: '/clients/:clientId/helpline-records/:helplineRecordId',
            element: <HelplineRecord />,
          },
          {
            path: '/clients/:clientId/insights',
            element: <PersonalInsights />,
          },
          {
            path: '/insights',
            element: <Insights />,
          },
          {
            path: '/billing',
            element: <Billing />,
          },
          {
            path: '/billing/:billingDay/break-up',
            element: <BillingDetails />,
          },
          {
            path: '/assignments',
            element: <Assignments />,
          },
          {
            path: '/rooms/:channel/:meetingId',
            element: <VideoRoom />,
          },
          {
            path: '/book-session',
            element: <GetProviderWizard />,
          },
          {
            path: '/carenotes/:serviceType/:clientId/:meetingId',
            element: <CareCaseNotes />,
          },
          {
            path: '/coachingnotes/:serviceType/:clientId/:meetingId',
            element: <CaseNote />,
          },
          {
            path: '/settings',
            element: <Settings />,
          },
          {
            path: '/availability',
            element: <Availability />,
          },
          {
            path: '/working-hours',
            element: <WorkingHours />,
          },
          {
            path: '/beta/calendar',
            element: <CalendarSync />,
          },
          {
            path: '/messages',
            element: <MessagesWrapper />,
          },
          ...oldRemovedRoutes.map((route) => ({
            path: route,
            element: <Navigate to="/" replace />,
          })),
        ],
      },
    ],
  },
]);

function AppRoutes() {
  return (
    <AnimatePresence>
      <Suspense fallback={<Loader />}>
        <RouterProvider router={router} />
      </Suspense>
    </AnimatePresence>
  );
}

export default AppRoutes;
