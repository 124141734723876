import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useFetch } from '../../../utilities/hooks/useFetch';
import Loader from '../../components/Loader';
import VideoCallRoom from '../../screens/VideoCallRoom/VideoCallRoom';
import { VideoPlatformCodes } from '../../../shared/constant/App';
import {
  IMeetingInfo,
  IMeetingStatus,
} from '../../../shared/types/response/provider';
import Header from './DailyRoom/components/Header/Header';
import { getMeetingDetails } from '../../../shared/service/API/meeting';
import { getDailyCoUrlFromRoomId } from '../../../v2/utilities/common/Session';

function VideoRoom() {
  const navigate = useNavigate();

  const { channel, meetingId } = useParams();
  if (!channel || !meetingId || !(channel in VideoPlatformCodes)) {
    toast.error('Invalid Meeting Id or Channel');
    window.history.back();
    return null;
  }

  const { data, isLoading } = useFetch<IMeetingInfo>(
    [channel, meetingId, 'meetingDetailsVideo'],
    () => getMeetingDetails(meetingId, channel),
    {
      onError: (error: any) => {
        toast.error(error?.message || 'Something went wrong');
        navigate('/');
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  console.log('data', data);

  const meetingData = React.useMemo<IMeetingStatus | undefined>(() => {
    if (data && !isLoading) {
      return {
        status: 'not-started',
        data: {
          clientId: data.meeting.participants?.[0]?.userId,
          meetingId: data.meeting.id,
          meetingDate: data.meeting.scheduledDate,
          providerRole: data.meeting.providerRole,
          userCreatedAt: data.meeting.participants?.[0]?.createdAt,
          tags: data.meeting.participants?.[0].tags,
          friendlyName: data.meeting.participants?.[0].friendlyName,
        },
      };
    }
    return undefined;
  }, [data, isLoading]);
  const locationRef = React.useMemo(() => {
    if (channel === '5' && !isLoading) {
      return getDailyCoUrlFromRoomId(data?.meeting?.locationRef ?? '');
    }
    return data?.meeting?.locationRef;
  }, [data?.meeting?.locationRef, isLoading]);

  if (!isLoading && !!locationRef && !!meetingData) {
    return (
      <>
        <Header meetingData={meetingData?.data} />
        <VideoCallRoom
          channel={VideoPlatformCodes[channel]}
          locationRef={locationRef}
          token={data?.token}
          meeting={meetingData}
          isFirstMeeting={!data?.completedMeeting}
        />
      </>
    );
  }
  return <Loader useFullPage />;
}
export default VideoRoom;
