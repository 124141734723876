import moment from 'moment';
import Config from '../../../../shared/Config';
import NetworkClient from '../Network';
import { beautifyUrl } from '../../../../utilities/common/Path';
import { getSlotsByWeekDay, getTimeZone } from '../../../utilities/common/Date';
import { virtualLocationKey } from '../../constant/Provider';
import { ISlots } from '../../types/Availability';
import { getProviderRoleLabels } from '../../../utilities/common/Clients';

const client = new NetworkClient();

export const getProviderWorkingHours = () =>
  client
    .doGet(Config.apiV2.availability.availability)
    .then((response) => {
      if (response?.data) {
        const slotsByWeekDay = getSlotsByWeekDay(
          response.data?.slots || [],
          getTimeZone(),
        );

        Object.keys(slotsByWeekDay).forEach((day) => {
          const { slotsRange, ...rest } = slotsByWeekDay[day];
          slotsByWeekDay[day] = {
            ...rest,
            slotsRange: slotsRange
              .map((slotRange) => ({
                ...slotRange,
                locationId: slotRange.locationId || virtualLocationKey,
              }))
              .sort((a, b) => (a.slots[0] > b.slots[0] ? 1 : -1)),
          };
        });

        return slotsByWeekDay;
      }
      return {};
    })
    .catch((error) => {
      console.error('Error occurred while fetching provider slots - ', error);
    });

export const saveWorkingHoursSlots = async (body: ISlots[]) => {
  const response = await client.doPost(
    Config.apiV2.availability.updateWorkingHours,
    body,
  );
  if (response?.success) {
    return true;
  }

  throw new Error('Unable to update working hours, please try again!');
};

export const getProviderOpenSlots = async (
  providerId: string,
  providerRole: string,
  clientId: string,
  meetingId?: string,
) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.slots.getProviderOpenSlots, [providerId]),
    {},
    {
      role: providerRole,
      userId: clientId,
      meetingId,
    },
  );
  if (response?.data) {
    return response.data;
  }

  throw new Error('Unable to fetch provider slots, please try again!');
};

export interface IBookSessionProps {
  scheduledStartTime: string;
  duration: string;
  role: string;
  type: string;
  source: string;
}

export const bookProviderSession = async (
  clientid: string,
  providerBookDetails: IBookSessionProps,
) => {
  const response = await client.doPost(
    beautifyUrl(Config.api.provider.bookProviderSession, [clientid]),
    providerBookDetails,
  );
  if (response?.success) {
    return {
      Date: moment
        .unix(Number(response.data?.scheduledStartTime))
        .tz(getTimeZone())
        .format('DD MMM, YYYY'),
      'Time slot': `${response.data.displayTime} - ${response.data.displayTimeEnd}`,
      'Session type': getProviderRoleLabels(response.data?.providerRole),
    };
  }
  if (response?.error) {
    throw new Error(response.error.message);
  }
  throw new Error('Unable to book session, please try again!');
};

export const getProviderProfileInfo = async (providerId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.profile.getProviderProfile, [providerId]),
  );
  if (response?.success) {
    return response.data;
  }
  throw new Error('Unable to fetch provider profile details');
};
