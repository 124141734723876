import { twMerge } from 'tailwind-merge';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

const audioMessagePreviewWrapperConfig =
  'bottom-full h-max w-full flex justify-start items-center box-border bg-white z-[3] pb-3';

interface IMessageInputAudioPreviewProps {
  audioStream: Blob;
  onClose: () => void;
  className?: string;
}

function MessageInputAudioPreview({
  audioStream,
  onClose,
  className,
}: Readonly<IMessageInputAudioPreviewProps>) {
  return (
    <div className={twMerge(audioMessagePreviewWrapperConfig, className)}>
      <AudioPlayer
        src={URL.createObjectURL(audioStream)}
        onDelete={onClose}
        source="inbound"
      />
    </div>
  );
}

export default MessageInputAudioPreview;
