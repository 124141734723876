import { ContextType, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import ClientDetailsContext from '../../../../../v2/view/screens/ClientDetails/context/ClientDetailsContext';
import { useClientDetailsV2 } from '../../../../../v2/view/screens/ClientDetails/hooks/UseClientDetails';
import { encodeCode } from '../../../../../v2/utilities/common/Encryption';
import { toSentenceCaseMultipleWords } from '../../../../../v2/utilities/common/Display';
import { QuickOverview } from './QuickOverview/QuickOverview';
import { ChatBookSessionModal } from './QuickOverview/ChatBookSessionModal';

export function ChatHeaderComponents({
  showBookModal,
  handleBookModalClose,
  userId,
  providerRole,
  showQuickOverview,
}: {
  showBookModal: boolean;
  handleBookModalClose: () => void;
  showQuickOverview: boolean;
  userId: number;
  providerRole: string;
}) {
  const queryClient = useQueryClient();
  const { data: clientDetailData, isLoading: isClientDetailLoading } =
    useClientDetailsV2(
      userId.toString(),
      providerRole,
      showBookModal || showQuickOverview,
    );

  const isLoading = isClientDetailLoading;

  const caseId = encodeCode(Number(userId));

  const clientName =
    toSentenceCaseMultipleWords(
      clientDetailData?.profile?.name?.trim() ?? '',
    ) ||
    caseId ||
    'N/A';

  const clientDetailsContextValues = useMemo(
    () => ({
      clientDetailData,
      isLoading,
      decodedClientId: userId.toString(),
      encodedClientId: caseId,
      serviceType: providerRole,
      clientName,
      refreshData: () => queryClient.invalidateQueries(['provider']),
      context: 'messages',
    }),
    [clientDetailData, isLoading, userId, providerRole],
  ) satisfies ContextType<typeof ClientDetailsContext>;

  return (
    <ClientDetailsContext.Provider value={clientDetailsContextValues}>
      <QuickOverview open={showQuickOverview} />

      <ChatBookSessionModal
        open={showBookModal}
        handleClose={handleBookModalClose}
      />
    </ClientDetailsContext.Provider>
  );
}
