/* eslint-disable */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../v2/view/components/Button';

const readMoreBtnConfig =
  'h-fit text-left text-primary-600 text-sm p-0 cursor-pointer hover:bg-transparent active:focus:bg-transparent';
interface ITextMessageItemProps {
  message: string;
  source?: string;
}

function TextMessageItem({ message, source }: Readonly<ITextMessageItemProps>) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isLongMessage, setIsLongMessage] = React.useState(false);
  const messageRef = React.useRef<HTMLDivElement>(null);
  function linkify(text: string) {
    const urlRegex =
      /\b((https?|ftp|file):\/\/|(www|ftp)\.)[-A-Z0-9+&@#\/%?=~_|$!:,.;]*[A-Z0-9+&@#\/%=~_|$]/gi;
    return text.replace(urlRegex, function (url) {
      return `<a href='${url}' target='_blank'> ${url} </a> `;
    });
  }
  // Check if the message is long enough to require a "Read more" button
  React.useEffect(() => {
    if (messageRef.current) {
      const isMessageLong =
        messageRef.current.scrollHeight > messageRef.current.clientHeight;
      setIsLongMessage(isMessageLong);
    }
  }, [message]);

  return (
    <section className="w-full whitespace-pre-line text-sm font-normal max-w-md break-words">
      <div
        ref={messageRef}
        className={`text-gray-700 [&>a]:text-primary-600 ${
          isExpanded ? '' : 'line-clamp-4'
        }`}
        dangerouslySetInnerHTML={{ __html: linkify(message) }}
      />
      {isLongMessage && !isExpanded && (
        <Button
          onClick={() => setIsExpanded(true)}
          variant="ghost"
          className={readMoreBtnConfig}
        >
          {t('READ_MORE')}
        </Button>
      )}
    </section>
  );
}

export default TextMessageItem;
