import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import { ReactComponent as RedirectionArrowIcon } from '../../../../v2/assets/linkArrow.svg';
import { encodeCode } from '../../../../utilities/common/Encryption';
import { Button } from '../../../../v2/view/components/Button';
import { ReactComponent as RightLayoutIcon } from '../../../../v2/assets/layout-right.svg';
import { Tag } from '../../../../v2/view/components/Tag';
import { ConversationListDataType } from '../../../data-types/ClientTypes';
import Tooltip from '../../../../v2/view/components/Tooltip';
import useLayout from '../../../../utilities/hooks/useLayout';

const chatHeaderWrapperConfig = twMerge(
  'box-border w-full shrink-0 flex justify-between items-center',
  'h-[86px] p-[18px]',
  'border-b border-solid border-gray-200',
);
const headerTagsWrapperConfig =
  'w-[49%] flex flex-col justify-start items-start gap-4 px-[5px]';
const clientNameButtonConfig = cva(
  'h-3 hover:bg-transparent active:focus:bg-transparent p-0 text-base font-semibold',
  {
    variants: {
      cursorBehaviour: {
        pointer: 'cursor-pointer',
        default: 'cursor-default',
      },
    },
  },
);

export interface ChatHeaderProps {
  userMetaData: ConversationListDataType;
  setShowQuickOverview: React.Dispatch<React.SetStateAction<boolean>>;
  showBookModal: () => void;
}

function ChatHeader({
  userMetaData,
  setShowQuickOverview,
  showBookModal,
}: Readonly<ChatHeaderProps>) {
  const { friendlyName, userId, tags, providerRole } = userMetaData;
  const { t } = useTranslation();
  const { isInFloatingWindow } = useLayout();
  const navigate = useNavigate();
  const { track } = useTracking();

  const tagArray = tags?.split(',')?.map((tag) => tag?.trim());
  // Determining if we have more than 3 tags
  const displayedTags = tagArray?.slice(0, 3); // Displaying only the first 3 tags data
  const remainingTagsCount = tagArray && tagArray.length - 3;

  const handleRedirection = () => {
    if (isInFloatingWindow) {
      track(EventNames.clientView, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.clientView,
        eventLabel: 'client_view',
        featureVersion: 'v1',
        eventSource: 'chat',
      });
      navigate(`/clients/${providerRole}/${encodeCode(Number(userId))}`);
    }
  };
  const cursorBehaviour = isInFloatingWindow ? 'pointer' : 'default';
  return (
    <div className={chatHeaderWrapperConfig}>
      <div className={headerTagsWrapperConfig}>
        <Button
          variant="ghost"
          className={clientNameButtonConfig({ cursorBehaviour })}
          onClick={handleRedirection}
        >
          <section className="flex items-center gap-0.5 ">
            <span className="max-w-24 truncate shrink">{friendlyName}</span>
            {isInFloatingWindow && (
              <RedirectionArrowIcon style={{ color: '#98A2B3' }} />
            )}
          </section>
        </Button>
        {tags && (
          <section className="flex gap-1.5 w-[248px] items-center">
            {displayedTags?.map((tag, index) => (
              <Tag
                key={tag}
                variant="grayOutline"
                tooltipId={`${tag}-${index}`}
                className="truncate min-w-5 shrink h-[18px] text-xs px-2 pb-0.5"
              >
                {tag}
                <Tooltip
                  id={`${tag}-${index}`}
                  content={tag}
                  placement="bottom"
                />
              </Tag>
            ))}
            {!!remainingTagsCount && remainingTagsCount > 0 && (
              <span
                data-tooltip-id="remaining-tags"
                className="cursor-pointer text-xs text-gray-600"
              >
                {`+${remainingTagsCount}`}
                <Tooltip
                  id="remaining-tags"
                  content={tagArray.slice(3).join()}
                  placement="bottom"
                />
              </span>
            )}
          </section>
        )}
      </div>
      {!isInFloatingWindow && (
        <div className={twMerge('flex items-center justify-between gap-3')}>
          <Button variant="secondary" onClick={showBookModal}>
            {t('BOOK_SESSION')}
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowQuickOverview((prev) => !prev)}
          >
            <RightLayoutIcon />
          </Button>
        </div>
      )}
    </div>
  );
}

export default ChatHeader;
