import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as TickIcon } from '../../../../v2/assets/tick.svg';
import { ReactComponent as CloseIcon } from '../../../../v2/assets/close.svg';
import { ReactComponent as RecordingIcon } from '../../../../v2/assets/Pause.svg';
import { ReactComponent as StopIcon } from '../../../../v2/assets/stop.svg';
import { Button } from '../../../../v2/view/components/Button';
import useAudioRecorder from '../../../utils/hooks/useAudioRecorder';

const recorderWrapperConfig = twMerge(
  'w-[calc(100%-120px)] h-12',
  'text-xs font-medium',
  'border solid border-gray-300 rounded-lg',
  'flex items-center justify-between py-3 px-3.5',
);

interface IChatVoiceRecorderProps {
  onRecordingEnd: (recordedAudio: Blob) => void;
  onRecordingCancel: () => void;
}

function ChatVoiceRecorder({
  onRecordingEnd,
  onRecordingCancel,
}: Readonly<IChatVoiceRecorderProps>) {
  const { t } = useTranslation();
  const {
    startRecording,
    stopRecording,
    audioBlob,
    pauseRecording,
    recordingPaused,
    recordingStarted,
    duration,
  } = useAudioRecorder();

  const onRecordingStart = () => {
    startRecording();
  };

  const onRecordingPause = () => {
    pauseRecording();
  };

  const onRecordingStop = () => {
    stopRecording();
    onRecordingEnd(audioBlob as Blob);
  };

  const cancelRecording = () => {
    stopRecording();
    onRecordingCancel();
  };
  React.useEffect(() => {
    onRecordingStart();

    return () => onRecordingStop();
  }, []);

  React.useEffect(() => {
    if (audioBlob) {
      onRecordingEnd(audioBlob.slice());
      onRecordingCancel();
    }
  }, [audioBlob]);

  const recordingStatus =
    recordingStarted && recordingPaused
      ? 'RECORDING_PAUSED'
      : 'RECORDING_IN_PROGRESS';

  return (
    <div className="w-full flex justify-between items-center gap-3">
      <Button
        variant="secondary"
        onClick={cancelRecording}
        className="h-12 w-12 items-center pl-4"
      >
        <CloseIcon />
      </Button>
      <div className={recorderWrapperConfig}>
        <section className="flex justify-center items-center">
          <Button
            variant="ghost"
            className="px-0.5 hover:bg-transparent active:focus:bg-transparent"
            onClick={onRecordingPause}
          >
            {recordingStarted && recordingPaused ? (
              <StopIcon />
            ) : (
              <RecordingIcon style={{ color: '#D92D20' }} className='mr-2'/>
            )}
          </Button>
          <div className="animate-pulse text-error-600">
            {t(recordingStatus)}
          </div>
        </section>
        <section>{duration}</section>
      </div>
      <Button variant="primary" onClick={onRecordingStop} className="h-12 w-12">
        <TickIcon className="h-6 w-6" />
      </Button>
    </div>
  );
}

export default ChatVoiceRecorder;
