import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../v2/view/components/Button';
import { ReactComponent as SettingsIcon } from '../../../v2/assets/settings-04.svg';
import { ReactComponent as SearchIcon } from '../../../assets/search.svg';
import Tooltip from '../../../v2/view/components/Tooltip';
import { Input } from '../../../v2/view/components/Input';
import { Select } from '../../../v2/view/components/Select';
import { IConversationRequestBody } from '../../data-types/ClientTypes';
import { useProviderServicesAndClinics } from '../../../v2/view/screens/Availability/hooks/UseAvailabilities';
import { RiskLevelsMappings } from '../../../v2/shared/constant/Clients';
import { MessageReadStatus } from '../../constants/CommonConstants';
import { riskColorVariants } from '../../../v2/shared/constant/Colors';
import useTracking from '../../../utilities/hooks/useTracking';
import { EventActions, EventNames } from '../../../shared/constant/Analytics';

interface IMessageUtilityPanel {
  title: string;
  tooltipText: string;
  onFiltersChange: (data: IConversationRequestBody) => void;
}

interface IFilterTracking {
  eventInfo: string;
  eventValue?: Array<string>;
}

function MessageUtilityPanel({
  title,
  tooltipText,
  onFiltersChange,
}: Readonly<IMessageUtilityPanel>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { track } = useTracking();
  const { data: providerServices } = useProviderServicesAndClinics();

  const [messageFilters, setMessageFilters] =
    React.useState<IConversationRequestBody>({
      page: 1,
    });

  const handleSettings = () => {
    navigate('/settings');
  };

  const getFilteredMappingsBasedOnRole = (providerRole: string[]) =>
    RiskLevelsMappings.filter((item) =>
      item.role.some((role) => providerRole.includes(role)),
    ).map((item) => ({
      ...item,
      label: riskColorVariants[item.value].label,
    }));

  const defaultSelectedButton = React.useMemo(() => {
    if (
      messageFilters.readStatus === MessageReadStatus.UNREAD &&
      messageFilters.riskLevel?.length !== 0
    ) {
      return 'secondary';
    }
    return 'grayed';
  }, [messageFilters]);

  const unreadSelectedButton = React.useMemo(() => {
    if (messageFilters.readStatus === MessageReadStatus.UNREAD) {
      return 'grayed';
    }
    return 'secondary';
  }, [messageFilters]);

  const handleFilters = (data: IConversationRequestBody) => {
    setMessageFilters(data);
    onFiltersChange(data);
  };

  const filterTracking = (instrumentationInfo: IFilterTracking) => {
    track(EventNames.viewMessageTabProv, {
      eventAction: EventActions.click,
      eventCategory: instrumentationInfo.eventInfo,
      eventLabel: instrumentationInfo.eventInfo,
      ...(instrumentationInfo.eventValue && {
        eventValue: instrumentationInfo.eventValue.map((val) =>
          t(riskColorVariants[val].label),
        ),
      }),
    });
  };

  return (
    <>
      <section className="flex justify-between items-center p-4 pb-0">
        <section className="w-4/5 text-displayXs font-semibold">
          {title}
        </section>
        <Button
          variant="ghost"
          onClick={handleSettings}
          className="hover:bg-transparent active:focus:bg-transparent"
        >
          <SettingsIcon data-tooltip-id="beacon-automated-message" />
          <Tooltip
            id="beacon-automated-message"
            content={tooltipText}
            placement="right"
          />
        </Button>
      </section>

      <section className="flex flex-col gap-4 px-4 pb-4 border-b solid border-gray-200">
        <Input
          containerClassName="mt-5 w-full"
          startIcon={<SearchIcon className="w-4 text-gray-400" />}
          placeholder={t('SEARCH_PROMPT')}
          value={messageFilters.searchTerm}
          onChange={(e) =>
            handleFilters({ ...messageFilters, searchTerm: e.target.value })
          }
          onClick={() => filterTracking({ eventInfo: 'search' })}
        />
        <section className="flex gap-2 items-center">
          <Button
            variant={defaultSelectedButton}
            onClick={() => {
              handleFilters({ page: 1, searchTerm: messageFilters.searchTerm });
              filterTracking({ eventInfo: 'all_messages_filter' });
            }}
          >
            {t('ALL')}
          </Button>
          <Button
            variant={unreadSelectedButton}
            onClick={() => {
              handleFilters({
                ...messageFilters,
                readStatus: MessageReadStatus.UNREAD,
              });
              filterTracking({ eventInfo: 'unread_messages_filter' });
            }}
          >
            {t('UNREAD')}
          </Button>
          <Select
            multiple
            options={getFilteredMappingsBasedOnRole(
              providerServices?.services || [],
            )}
            selected={messageFilters?.riskLevel || []}
            onSelect={(value) => {
              handleFilters({ ...messageFilters, riskLevel: value });
              filterTracking({
                eventInfo: 'risk_level_filter',
                eventValue: value,
              });
            }}
            placeholder={t('RISK_LEVEL')}
            className="max-w-20"
            listBoxClassName='max-h-64'
          />
        </section>
      </section>
    </>
  );
}

export default MessageUtilityPanel;
