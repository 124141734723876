import React from 'react';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { VideoRoomSDKs } from '../../../../shared/constant/App';
import useMeeting from '../../../../view/app-components/VideoRoom/hooks/useMeetingJoin';
import { ChatSource } from '../../../../shared/types/Chat';
import { Button } from '../../../../v2/view/components/Button';
import ClientOverviewCardLoader from '../../../../v2/view/screens/ClientDetails/ClientOverviewCardLoader';
import TextMessageItem from '../TextMessageItemV2';
import { getDailyCoUrlFromRoomId } from '../../../../v2/utilities/common/Session';

const ctaMessageConfig = cva(
  'w-full border-box p-3 flex flex-col justify-start items-start gap-3 rounded-lg',
  {
    variants: {
      source: {
        outbound: 'bg-primary-200',
        inbound: 'bg-blueDark-100',
      },
    },
  },
);

interface IAssignmentMessageItemProps {
  ctaContent: string;
  message: string;
  source: ChatSource;
  updateChatWindowSettings: (settings: any) => void;
}

function CtaMessageItem({
  ctaContent,
  message,
  source,
  updateChatWindowSettings,
}: Readonly<IAssignmentMessageItemProps>) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  // * Function to get the image uri
  const parsedMessage = JSON.parse(ctaContent);
  const { messageType, meetingId, type } = parsedMessage || {};

  const { isLoading, mutate: joinMeeting } = useMeeting({
    onSuccess: (meetingData) => {
      const dailyCoUrl = getDailyCoUrlFromRoomId(
        meetingData?.meeting?.locationRef || '',
      );
      navigate(
        `/rooms/${VideoRoomSDKs.dailyCo}/${meetingData.meeting.id}/?roomUrl=${dailyCoUrl}`,
      );
      updateChatWindowSettings({ show: false });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const joinSession = () => {
    if (type === VideoRoomSDKs.dailyCo) {
      joinMeeting({ meetingId, channel: VideoRoomSDKs.dailyCo });
    } else {
      navigate(`/rooms/${type}/${meetingId}`);
      updateChatWindowSettings({ show: false });
    }
  };
  if (isLoading) {
    return (
      <section className="grid grid-row-8 gap-4">
        <ClientOverviewCardLoader variant="credits" />
      </section>
    );
  }
  return (
    <section className={ctaMessageConfig({ source })}>
      <TextMessageItem message={message}/>
      {messageType === 'join' && (
        <Button
          onClick={() => joinSession()}
          disabled={moment().unix() > parsedMessage?.validity}
          className="w-full rounded-full disabled:bg-primary-300"
        >
          {t('joinSession')}
        </Button>
      )}
    </section>
  );
}

export default CtaMessageItem;
