import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultScreenItems } from '../../../constants/CommonConstants';

function NoConversations() {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % DefaultScreenItems.length,
      );
    }, 8000); // Change slide every 5 seconds

    return () => clearInterval(intervalId);
  }, []);
  return (
    <section className="relative overflow-hidden max-w-[485px] flex items-center justify-center m-auto">
      <section
        className="flex transition-transform duration-500"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {DefaultScreenItems.map((item) => (
          <section key={item.imageSrc} className="w-full flex-shrink-0 flex items-center justify-center">
            <section className="flex flex-col h-full m-auto items-center justify-center w-full">
              <figure className="w-full text-center">
                <img
                  src={item.imageSrc}
                  alt="chat icon"
                  className="m-auto p-3 w-[320px] h-[256px]"
                />
                <p className="text-xl font-semibold">{t(item.title)}</p>
                <figcaption className="text-base text-gray-600">
                  {t(item.content)}
                </figcaption>
              </figure>
            </section>
          </section>
        ))}
      </section>
    </section>
  );
}

export default NoConversations;
