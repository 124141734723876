import moment from 'moment';
import { beautifyUrl } from '../../../utilities/common/Path';
import Config from '../../Config';
import NetworkClient from '../../../v2/shared/service/Network';
import { IBookSessionProps } from '../../types/request/booking';
import { getTimeZone } from '../../../utilities/common/Date';
import { getProviderRoleLabels } from '../../../v2/utilities/common/Clients';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to perform Provider Book action, please try again!',
};

export const getProviderOpenSlots = async (
  providerId: string,
  providerRole: string,
  clientId: string,
  meetingId?: string,
) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.slots.getProviderOpenSlots, [providerId]),
    {},
    {
      role: providerRole,
      userId: clientId,
      meetingId,
    },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getproviderClientDetails = async (
  clientId: number,
  role: string,
) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.provider.getproviderClientDetails, [
      clientId.toString(),
    ]),
    {},
    {
      role,
    },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getCasenotesQuestions = async () => {
  const response = await client.doGet(Config.api.caseNotes.questions);
  if (response && response.data) {
    return response.data;
  }
  throw new Error(errorMessages.generic);
};

export const bookProviderSession = async (
  clientid: string,
  providerBookDetails: IBookSessionProps,
) => {
  const response = await client.doPost(
    beautifyUrl(Config.api.provider.bookProviderSession, [clientid]),
    providerBookDetails,
  );
  if (response && response.success) {
    return {
      Date: moment
        .unix(Number(response.data?.scheduledStartTime))
        .tz(getTimeZone())
        .format('DD MMM, YYYY'),
      'Time slot': `${response.data.displayTime} - ${response.data.displayTimeEnd}`,
      'Session type': getProviderRoleLabels(response.data?.providerRole),
    };
  }

  throw new Error(
    response?.error?.message || response?.details || errorMessages.generic,
  );
};

export const requestCreditSession = async (
  userId: string,
  requestCreditsDetails: { userId: string; creditType: number },
) => {
  const response = await client.doPost(
    Config.api.provider.requestCredits,
    requestCreditsDetails,
  );
  if (response && response.success) {
    return true;
  }

  throw new Error(
    response?.error?.message || response?.details || errorMessages.generic,
  );
};

export const getProviderServices = async (providerId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.provider.getProviderServices, [providerId]),
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getProviderConfigDetails = async () => {
  const response = await client.doGet(Config.api.provider.getProviderConfig);
  if (response && response.data) {
    return response.data;
  }
  throw new Error(errorMessages.generic);
};

export const updateProviderTimezone = async ({
  providerId,
  newTimezone,
}: {
  providerId: string;
  newTimezone: string;
}) => {
  const response = await client.doPut(
    beautifyUrl(Config.api.provider.updateTimeZone, [providerId]),
    {
      timezone: newTimezone,
    },
  );
  if (response && response.data) {
    return response.data;
  }
  throw new Error(response?.error?.message);
};

export const requestCustomIssue = async (body: {
  meetingId: string;
  topic: string;
  description: string;
}) => {
  const response = await client.doPost(Config.api.provider.requestIssue, body);
  if (response && response.success) {
    return true;
  }

  throw new Error(
    response?.error?.message || response?.details || errorMessages.generic,
  );
};
