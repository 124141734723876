import { useMutation } from 'react-query';
import {
  IBookSessionProps,
  bookProviderSession,
} from '../../../../shared/service/API/provider';

export const useBookSession = (
  clientid: string,
  providerBookDetails: IBookSessionProps,
  {
    onSuccess,
    onError,
  }: {
    onSuccess: (data: Record<string, string>) => void;
    onError: (error: Error) => void;
  },
) =>
  useMutation(() => bookProviderSession(clientid, providerBookDetails), {
    onSuccess,
    onError,
  });
