import * as React from 'react';
import { cva } from 'class-variance-authority';
import FloatingWidget from '../FloatingWidget/FloatingWidget';
import MessagesWrapper from '../MessagesWraper';

export interface FloatingChatWindowV2Props {
  viewMode?: 'full-view' | 'chat-view';
}
const chatWindowContainerConfig = cva(
  'h-fit flex relative justify-start items-start border solid border-gray-200 rounded-2xl shadow-[5px_1px_67px_-3px_rgba(0,0,0,0.56)]',
  {
    variants: {
      view: {
        full: 'w-[804px]',
        chat: 'w-[500px]',
      },
    },
  },
);

function FloatingChatWindowV2({
  viewMode = 'chat-view',
}: Readonly<FloatingChatWindowV2Props>) {
  const view = viewMode === 'full-view' ? 'full' : 'chat';
  return (
    <FloatingWidget
      initialPosition={{ right: '2rem', bottom: '6rem' }}
      canDrag
    >
      <section className={chatWindowContainerConfig({ view })}>
        <MessagesWrapper viewMode={viewMode} />
      </section>
    </FloatingWidget>
  );
}

export default FloatingChatWindowV2;
