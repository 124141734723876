import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';
import { cva } from 'class-variance-authority';
import Avatar from '../../../../v2/view/components/Avatar';
import { getUnreadMessagesCountForFloatingChatButton } from '../../../../utilities/common/Display';

const nameMessageConfig =
  'flex flex-col justify-center items-start w-[214px] font-medium text-sm text-gray-900 capitalize';
const messageTimeStatusConfig =
  'min-w-[42px] w-full flex flex-col justify-start items-end gap-1.5 text-[10px] font-normal text-gray-500';
const messagesStatusConfig = cva(
  'w-5 h-5 text-center py-0.5 rounded-[50%] text-xxs text-white font-bold',
  {
    variants: {
      messageReadStatus: {
        read: 'bg-primary-600',
        unread: 'bg-transparent',
      },
    },
  },
);
const messagesConfig = cva('w-[214px] truncate text-xs', {
  variants: {
    messageReadStatus: {
      unread: 'font-normal text-gray-500',
      read: 'font-semibold text-gray-900',
    },
  },
});
export interface IChatContactProps {
  name: string;
  message: string;
  messagedTime: string;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  messagesUnread: number;
}

function ChatContact({
  name,
  message,
  messagedTime,
  selected,
  onClick,
  messagesUnread,
}: Readonly<IChatContactProps>) {
  const messageReadStatus = messagesUnread ? 'read' : 'unread';
  return (
    <motion.section
      className={twMerge(
        'max-w-[360px] h-[66px] p-3 rounded-lg flex flex-row justify-start items-center box-border cursor-pointer',
        selected ? 'bg-gray-100 border solid border-gray-200' : '',
      )}
      whileHover={{ scale: 1.05 }}
      onClick={onClick}
    >
      <section className="mr-2">
        <Avatar clientName={name} className="h-9 w-9" />
      </section>
      <section className={nameMessageConfig}>
        <section className="w-[214px] truncate">{name}</section>
        <section className={messagesConfig({ messageReadStatus })}>
          {message}
        </section>
      </section>
      <section className={messageTimeStatusConfig}>
        {messagedTime}
        <section className={messagesStatusConfig({ messageReadStatus })}>
          {messagesUnread
            ? getUnreadMessagesCountForFloatingChatButton(messagesUnread)
            : ''}
        </section>
      </section>
    </motion.section>
  );
}

export default React.forwardRef(ChatContact);
