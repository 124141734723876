import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useLocation, Link } from 'react-router-dom';
import intellectLogo from '../../assets/IntellectLogo.svg';
import { ReactComponent as SidebarCloseIcon } from '../../assets/close.svg';
import { ReactComponent as AssignmentIcon } from '../../assets/assignment.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendarWithDate.svg';
import { ReactComponent as InsightIcon } from '../../assets/insightsLineChart.svg';
import { ReactComponent as PersonMultipleIcon } from '../../assets/personMultiple.svg';
import { ReactComponent as LinkIcon } from '../../assets/linkArrow.svg';
import { ReactComponent as BillingIcon } from '../../assets/billingWallet.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';
import { ReactComponent as MessagesIcon } from '../../assets/message-chat-circle.svg';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';
import { Button } from '../components/Button';
import { getCurrentLocale } from '../../../i18n';
import useTracking from '../../../utilities/hooks/useTracking';
import { externalUrls } from '../../../shared/Config';
import { useChatBroker } from '../../../chat-module/broker/useChatBroker';
import { getUnreadMessagesCountForFloatingChatButton } from '../../../utilities/common/Display';

const countWrapperConfig =
  'float-right w-5 h-5 py-0.5 bg-primary-600 text-center text-white rounded-full text-xxs font-extrabold';

interface ISideBarItems {
  label: string;
  route: string;
  icon: JSX.Element;
  instrumentation?: {
    eventName: string;
    eventAction: string;
    eventCategory: string;
    eventLabel: string;
    featureVersion?: string;
  };
}

const sidebarItems: ISideBarItems[] = [
  {
    label: 'AVAILABILITY_HEADER',
    route: '/availability',
    icon: <CalendarIcon />,
    instrumentation: {
      eventName: EventNames.availability,
      eventAction: EventActions.click,
      eventCategory: EventCategories.availability,
      eventLabel: 'availability',
    },
  },
  {
    label: 'clients',
    route: '/clients',
    icon: <PersonMultipleIcon />,
  },
  {
    label: 'messages',
    route: '/messages',
    icon: <MessagesIcon />,
    instrumentation: {
      eventName: EventNames.viewMessageTabProv,
      eventAction: EventActions.click,
      eventCategory: EventCategories.viewMessageTabProv,
      eventLabel: 'view_message_tab_prov',
    },
  },
  {
    label: 'assignments',
    route: '/assignments',
    icon: <AssignmentIcon />,
  },
  {
    label: 'INSIGHTS',
    route: '/insights',
    icon: <InsightIcon />,
  },
  {
    label: 'billingHeader',
    route: '/billing',
    icon: <BillingIcon />,
  },
  {
    label: 'SETTINGS_TAB',
    route: '/settings',
    icon: <SettingsIcon />,
    instrumentation: {
      eventName: EventNames.settings,
      eventAction: EventActions.click,
      eventCategory: EventCategories.settings,
      eventLabel: 'settings',
      featureVersion: 'v1',
    },
  },
];

export const resources = [
  {
    label: 'Provider FAQ',
    link: {
      en: externalUrls.providerFAQ.providerFAQLinkEn,
      ja: externalUrls.providerFAQ.providerFAQLinkJa,
    },
  },
  {
    label: 'PROVIDER_HANDBOOK',
    link: {
      en: externalUrls.providerHandbook.notionLinkEn,
      ja: externalUrls.providerHandbook.notionLinkJa,
    },
  },
  {
    label: 'Intellect Academy',
    link: {
      en: externalUrls.talentLMSCourse.talentLinkEn,
      ja: externalUrls.talentLMSCourse.talentLinkJa,
    },
  },
] as const;

interface SideNavBarProps {
  collapsed: boolean;
  handleCloseSidebar: () => void;
}

export function Sidebar({
  collapsed,
  handleCloseSidebar,
}: Readonly<SideNavBarProps>) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const { unreadCount } = useChatBroker();
  const currentLanguage =
    getCurrentLocale() as keyof (typeof resources)[number]['link'];
  const location = useLocation();

  const onSidebarButtonClick = (
    instrumentation?: (typeof sidebarItems)[number]['instrumentation'],
  ) => {
    if (instrumentation) {
      track(instrumentation.eventName, {
        eventAction: instrumentation.eventAction,
        eventCategory: instrumentation.eventCategory,
        eventLabel: instrumentation.eventLabel,
        ...(instrumentation.featureVersion && {
          featureVersion: instrumentation.featureVersion,
        }),
      });
    }
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        aria-label="sidebar backdrop"
        onClick={handleCloseSidebar}
        className={twMerge(
          'fixed overflow-hidden top-0 cursor-pointer opacity-0 left-0 h-screen w-screen bg-black z-[9] transition-all',
          collapsed ? 'invisible' : 'visible opacity-50',
        )}
      />
      <div
        style={{ fontFamily: 'Inter, sans-serif' }} // temporary till we change the global font
        className={twMerge(
          'fixed lg:top-[3.75rem] top-0 left-0 h-full w-60 bg-white z-10 transition-transform duration-500 border-r solid border-gray-200',
          collapsed
            ? 'sm:-translate-x-full lg:translate-x-0'
            : 'sm:translate-x-0',
        )}
      >
        <div className="p-5 lg:hidden flex items-center justify-between w-full gap-2">
          <img
            src={intellectLogo}
            alt="intellect logo"
            className="w-[7.5rem]"
          />
          {collapsed ? null : (
            <button type="button" onClick={handleCloseSidebar}>
              <SidebarCloseIcon className="text-gray-500 h-4 w-4" />
            </button>
          )}
        </div>

        <div className="flex flex-col gap-2 w-full p-5 ">
          {sidebarItems.map((sidebarItem) => (
            <Link
              to={sidebarItem.route}
              onClick={() => handleCloseSidebar()}
              className="underline-offset-1"
              key={`${sidebarItem.label}-${sidebarItem.route}`}
            >
              <Button
                variant="secondary"
                startIcon={sidebarItem.icon}
                onClick={() =>
                  onSidebarButtonClick(sidebarItem?.instrumentation)
                }
                className={twMerge(
                  'text-left font-medium w-full',
                  location.pathname.includes(sidebarItem.route)
                    ? 'border border-primary-100 text-primary-600 bg-primary-50'
                    : 'border-none',
                )}
              >
                {t(sidebarItem.label)}
                {sidebarItem.label === 'messages' && unreadCount > 0 && (
                  <span className={countWrapperConfig}>
                    {getUnreadMessagesCountForFloatingChatButton(unreadCount)}
                  </span>
                )}
              </Button>
            </Link>
          ))}
        </div>
        <div className="p-5">
          <div className="text-gray-400 mb-3">{t('RESOURCES')}</div>
          <div className="flex flex-col gap-2 w-full">
            {resources.map((externalUrl) => (
              <a
                key={`${externalUrl.label}-${externalUrl.link?.[currentLanguage]}`}
                href={externalUrl.link?.[currentLanguage]}
                target="_blank"
                className="hover:no-underline"
                rel="noreferrer"
              >
                <Button
                  variant="secondary"
                  startIcon={<LinkIcon />}
                  className="border-0 font-medium"
                >
                  {t(externalUrl.label)}
                </Button>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
