import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { IConversationRequestBody } from '../data-types/ClientTypes';

// Create the context
const ConversationRequestContext = createContext<
  | {
      conversationRequestBody: IConversationRequestBody;
      setConversationRequestBody: React.Dispatch<
        React.SetStateAction<IConversationRequestBody>
      >;
    }
  | undefined
>(undefined);

// Create a provider component

export function ConversationRequestProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [conversationRequestBody, setConversationRequestBody] =
    useState<IConversationRequestBody>({ page: 1 });

  const location = useLocation();

  useEffect(() => {
    setConversationRequestBody({ page: 1 });
  }, [location]);

  const value = useMemo(
    () => ({ conversationRequestBody, setConversationRequestBody }),
    [conversationRequestBody, setConversationRequestBody],
  );

  return (
    <ConversationRequestContext.Provider value={value}>
      {children}
    </ConversationRequestContext.Provider>
  );
}

// Create a custom hook to use the context
export const useConversationRequest = () => {
  const context = useContext(ConversationRequestContext);
  if (!context) {
    throw new Error(
      'useConversationRequest must be used within a ConversationRequestProvider',
    );
  }
  return context;
};
