import { IProviderData } from '../../../shared/types/response/provider';
import { useFetch } from '../../../utilities/hooks/useFetch';
import { getProviderProfileInfo } from '../../shared/service/API/provider';

function useProviderProfile(userId: string) {
  const { data: providerProfileInfo, isLoading: isProfileLoading } =
    useFetch<IProviderData>(
      ['getAutomatedMessageTriggers', userId],
      ()=>getProviderProfileInfo(userId),
      {
        refetchOnWindowFocus: false,
        cacheTime: 24 * 60 * 60 * 1000,
        staleTime: 24 * 60 * 60 * 1000,
      },
    );
  return {
    providerProfileInfo,
    isProfileLoading,
  };
}

export default useProviderProfile;
