import * as React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as MessageIcon } from '../../../assets/message.svg';
import useLayout from '../../../utilities/hooks/useLayout';
import { useChatBroker } from '../../broker/useChatBroker';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import { ReactComponent as CloseIcon } from '../../../v2/assets/ChevronDown.svg';

const floatingChatButton = `
  fixed
  bottom-8
  right-8
  w-14
  h-14
  rounded-full
  bg-primary-600
  flex
  justify-center
  items-center
  cursor-pointer
  z-[9999]
`;

const unreadMessageCount = `
  absolute
  w-3
  h-3
  rounded-full
  inset-y-0 
  right-0
  bg-error-500
`;

export interface IChatFloatingButton {
  show: boolean;
}

function ChatFloatingButton({ show }: Readonly<IChatFloatingButton>) {
  const { updateChatWindowSettings } = useLayout();
  const { track } = useTracking();
  const { unreadCount } = useChatBroker();

  const handleChatButtonClick = () => {
    updateChatWindowSettings({ show: !show, isInFloatingWindow: !show, viewMode: 'full-view' });
    track(EventNames.messages, {
      eventAction: EventActions.click,
      eventCategory: show ? EventCategories.minimize : EventCategories.showMessages,
      eventLabel: show ? 'minimize' : 'show_messages',
      featureVersion: 'v1',
      eventSource: EventSource.chatButton,
    });
  };

  return (
    <motion.button
      className={floatingChatButton}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={handleChatButtonClick}
    >
      <div className="relative flex justify-center items-center text-white">
        {show ? <CloseIcon className="m-auto" /> : <MessageIcon />}
        {!!unreadCount && !show && <motion.div className={unreadMessageCount} />}
      </div>
    </motion.button>
  );
}

export default ChatFloatingButton;
