import * as React from 'react';
import { motion } from 'framer-motion';
import useLayout from '../../../../utilities/hooks/useLayout';
import { IElementPosition } from '../../../../shared/types/App';

const floatingWidgetConfig = `
  fixed
  h-max
  w-max
  z-[11]
`;
interface IFloatingWidget {
  children: React.ReactNode;
  canDrag?: boolean;
  initialPosition?: IElementPosition;
}

function FloatingWidget({
  children,
  canDrag = false,
  initialPosition,
}: Readonly<IFloatingWidget>) {
  const { layoutRef } = useLayout();
  return (
    <motion.div
      className={floatingWidgetConfig}
      drag={canDrag}
      dragConstraints={layoutRef}
      style={initialPosition || {}}
    >
      {children}
    </motion.div>
  );
}

export default FloatingWidget;
