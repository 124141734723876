import { t } from 'i18next';
import { CaseNoteFormType } from '../../../shared/constant/CoachingCaseNotes';

export const getCaseNoteOptions = (disabledOptions: string[]) => [
  {
    label: t('COACHING_NOTES'),
    value: CaseNoteFormType.V1,
    description: t('COACHING_FORM_SUBTITLE'),
    disabled: disabledOptions.includes(CaseNoteFormType.V1),
  },
  {
    label: t('INTAKE_FORM_HEADER'),
    value: CaseNoteFormType.V3,
    description: t('INTAKE_FORM_SUBTITLE'),
    disabled: disabledOptions.includes(CaseNoteFormType.V3),
  },
  {
    label: t('FOLLOW_FORM_HEADER'),
    value: CaseNoteFormType.V4,
    description: t('FOLLOW_FORM_SUBTITLE'),
    disabled: disabledOptions.includes(CaseNoteFormType.V4),
  },
  {
    label: t('BASIC_NOTES_HEADER'),
    value: CaseNoteFormType.MINIMAL,
    description: t('BASIC_NOTES_SUBTITLE'),
    disabled: disabledOptions.includes(CaseNoteFormType.MINIMAL),
  },
];
