import * as React from 'react';
import { scrollToBottom } from '../../../../utilities/common/UserExperience';

function useInfiniteScroll(
  onIntersect: () => void,
  callBackDep: Array<any>,
  observerDep: Array<any>,
  isBottom:boolean = false,
) {
  const chatContainerRef = React.useRef<HTMLDivElement>(null);
  const topMessageElementRef = React.useRef<HTMLDivElement>(null);
  const loadMoreChats = React.useCallback(
    (entries: any) => {
      const lastEntry = !isBottom ? entries[0] : entries[entries.length - 1];
      if (lastEntry.isIntersecting) {
        onIntersect();
      }
    },
    [onIntersect, ...callBackDep, isBottom],
  );

  React.useEffect(() => {
    let observer: IntersectionObserver;
    if (chatContainerRef.current) {
      if (!isBottom) {
        scrollToBottom(chatContainerRef);
      }
      observer = new IntersectionObserver(loadMoreChats, {
        root: chatContainerRef.current,
        threshold: 1.0,
      });

      if (topMessageElementRef.current) {
        observer.observe(topMessageElementRef.current);
      }
    }
    return () => {
      if (topMessageElementRef.current) {
        observer?.unobserve(topMessageElementRef.current);
      }
    };
  }, [chatContainerRef.current, topMessageElementRef.current, ...observerDep]);

  return { chatContainerRef, topMessageElementRef, isBottom };
}

export default useInfiniteScroll;
