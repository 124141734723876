/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

const variants = {
  primary:
    'bg-primary-600 hover:bg-primary-700 active:focus:bg-primary-800 text-white disabled:bg-primary-200',
  primaryOutline:
    'border border-primary-100 text-primary-600 bg-primary-50 hover:bg-primary-25 active:focus:bg-primary-100 disabled:bg-gray-50 disabled:text-gray-300 disabled:border-gray-200',
  secondary:
    'bg-white hover:bg-gray-100 active:focus:bg-gray-200 text-gray-700 border border-gray-300 disabled:text-gray-300 disabled:border-gray-200',
  ghost:
    'bg-transparent hover:bg-[rgba(0,0,0,.1)] active:focus:bg-[rgba(0,0,0,.15)]  disabled:bg-gray-50 disabled:text-gray-300',
  grayed:
    'bg-gray-50 hover:bg-gray-100 active:focus:bg-gray-200 text-gray-700 border border-gray-300 disabled:text-gray-300 disabled:border-gray-200',
} as const;

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  variant?: keyof typeof variants;
  mini?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
}

export function Button({
  variant = 'primary',
  type,
  mini,
  children,
  startIcon,
  endIcon,
  className,
  ...rest
}: PropsWithChildren<ButtonProps>) {
  return (
    <button
      className={twMerge(
        'transition-colors duration-300 ease-in-out text-sm font-semibold flex gap-3 items-center disabled:pointer-events-none outline-primary-100 outline-8 outline-offset-1',
        mini ? 'py-1 px-2 rounded-full' : 'py-2 px-3 h-10 rounded-lg',
        variants[variant],
        className,
      )}
      type={type || 'button'}
      {...rest}
    >
      {startIcon}
      <div className="grow">{children}</div>
      {endIcon}
    </button>
  );
}
