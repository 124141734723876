import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PDFDocument } from 'pdf-lib';
import { cva } from 'class-variance-authority';
import TextMessageItem from '../TextMessageItemV2';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import pdfIcon from '../../../../v2/assets/PDFFile.svg';
import { Button } from '../../../../v2/view/components/Button';

const documentMessageConfig = cva(
  'w-full h-auto p-3 border solid rounded cursor-pointer rounded-lg hover:bg-transparent active:focus:bg-transparent',
  {
    variants: {
      source: {
        outbound: 'bg-primary-200',
        inbound: 'bg-blueDark-100',
      },
    },
  },
);

interface IDocumentmsgProps {
  docUrl: string;
  docName: string;
  body?: string;
  source: 'inbound' | 'outbound';
}

interface IPdfDetails {
  pageCount: number | null;
  fileSize: number | null;
}

function DocumentMessageItem({
  docUrl,
  docName,
  body,
  source,
}: Readonly<IDocumentmsgProps>) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [pdfDetails, setPdfDetails] = React.useState<IPdfDetails>({
    pageCount: null,
    fileSize: null,
  });

  React.useEffect(() => {
    const fetchPdfDetails = async () => {
      try {
        const response = await fetch(docUrl);
        const arrayBuffer = await response.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        setPdfDetails({
          pageCount: pdfDoc.getPageCount(),
          fileSize: arrayBuffer.byteLength / (1024 * 1024),
        });
      } catch (error: any) {
        console.error('Error loading PDF:', error.message);
      }
    };

    fetchPdfDetails();
  }, [docUrl]);

  const handleDocClick = () => {
    track(EventNames.messages, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.clickAttachment,
      eventLabel: 'pdf',
    });
    window.open(docUrl, '_blank', 'noreferrer');
  };

  return (
    <>
      <Button
        variant="ghost"
        className={documentMessageConfig({ source })}
        onClick={handleDocClick}
      >
        <section className="flex items-start gap-3 justify-center">
          <img src={pdfIcon} alt="pdf-icon" className="w-8 h-10" />
          <section className="w-4/5 flex flex-col text-xs">
            <section className="text-left font-medium line-clamp-2">
              {t(docName)}
            </section>
            {pdfDetails.pageCount !== null && pdfDetails.fileSize !== null && (
              <ul className="w-full flex list-none text-gray-500">
                <li>
                  {pdfDetails.pageCount}{' '}
                  {pdfDetails.pageCount === 1 ? t('PAGE') : t('PAGES')}
                </li>
                <li className="list-disc ml-4">PDF</li>
                <li className="list-disc ml-4">
                  {pdfDetails.fileSize?.toFixed(2)} MB
                </li>
              </ul>
            )}
          </section>
        </section>
      </Button>
      {!!body && (
        <section className="w-full">
          <TextMessageItem source={source} message={body} />
        </section>
      )}
    </>
  );
}
export default DocumentMessageItem;
