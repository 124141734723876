import { isSameDay } from 'date-fns';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import { AvailableSessionDurations } from '../../../../shared/types/response/provider';
import useAuth from '../../../../utilities/hooks/useAuth';
import useTracking from '../../../../utilities/hooks/useTracking';
import Typography from '../../../../view/components/Typography';
import { getDurationList } from '../../../utilities/common/Clients';
import { Button } from '../../components/Button';
import { DatePicker } from '../../components/DatePicker';
import InfoTile from '../../components/InfoTile';
import Modal from '../../components/Modal';
import RadioButtonGroup from '../../components/RadioButtonGroup';
import { IClientDetail } from '../../screens/ClientDetails/types/client';
import CreditDetailsCard from './CreditDetailsCard/CreditDetailsCard';
import { SuccessComponentWithTickAnimation } from './ResultModal';
import SlotSelectList from './SlotSelectList';
import { APIDateFormat, getTimeZone } from '../../../utilities/common/Date';
import { ProviderCancellationReasons } from '../../screens/Availability/constants';
import { useBookSession } from './hooks/useBookSession';
import { useProviderOpenSlots } from './hooks/useProviderOpenSlots';
import { BoxLoader } from '../../components/Loader/BoxLoader';

type IBookSessionModalProps = {
  handleClose: () => void;
  clientData: {
    name: string;
    userId: string;
    providerRole: string;
    credits: IClientDetail['credits'];
    defaultSlotsDuration: IClientDetail['defaultSlotsDuration'];
  };
} & (
  | { reschedule?: never; onConfirm: () => void }
  | {
      /** If not undefined, this becomes a reschedule modal. */
      reschedule: {
        reason: string;
        duration: string;
        meetingId?: string;
      };
      onConfirm: (
        body: Record<'scheduledStartTime' | 'duration' | 'role', string>,
      ) => void;
    }
);

/**
 * **IMPORTANT**: Wrap this in a `<Modal>` before usage.
 * This is wrapped in one to facilitate reuse with the Reschedule flow.
 */
export function BookSessionComponent({
  clientData,
  handleClose,
  onConfirm,
  reschedule,
}: IBookSessionModalProps) {
  // Custom Hooks
  const { track } = useTracking();

  const { user } = useAuth();

  const { data: providerOpenSlots, isLoading: isProviderSlotsLoading } =
    useProviderOpenSlots(
      user.id,
      clientData.providerRole,
      clientData.userId,
      reschedule?.reason ===
        ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CHANGE_DURATION
        ? reschedule.meetingId
        : undefined,
    );

  const { t } = useTranslation();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [bookSuccessModalData, setBookSuccessModalData] = useState<Record<
    string,
    string
  > | null>(null);
  const [selectedDateInDatePicker, setSelectedDateInDatePicker] = useState<
    Date | undefined
  >();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [selectedSessionDuration, setSelectedSessionDuration] =
    useState<AvailableSessionDurations>('900');
  const [selectedSlot, setSelectedSlot] = useState<string>('');

  const handleResetStateOnSuccess = () => {
    setSelectedSessionDuration('');
    setSelectedDateInDatePicker(undefined);
    setSelectedDate(undefined);
    setSelectedSlot('');
  };

  const { mutate: bookProviderSessionFun, isLoading: isBookSessionLoading } =
    useBookSession(
      clientData.userId,
      {
        scheduledStartTime: selectedSlot,
        duration: selectedSessionDuration,
        role: clientData?.providerRole,
        type: '0',
        source: '5',
      },
      {
        onSuccess: (data) => {
          track(EventNames.providerBookSession, {
            eventAction: EventActions.click,
            eventCategory: EventCategories.confirmSession,
            eventLabel: 'provider_confirm_session',
          });

          handleResetStateOnSuccess();
          setBookSuccessModalData(data);
        },
        onError: (error: Error) => {
          const errorMessage = t('providerBookingErrorMessage', {
            clientName: clientData?.name,
          });
          toast.error(error.message || errorMessage);
        },
      },
    );

  //* Function to getAvailable Slots
  const getAvailableSlotsDates = () => {
    if (
      providerOpenSlots &&
      selectedSessionDuration &&
      providerOpenSlots[
        selectedSessionDuration as keyof typeof providerOpenSlots
      ]
    ) {
      return providerOpenSlots[
        selectedSessionDuration as keyof typeof providerOpenSlots
      ].map((slot) => slot.displayDate);
    }

    return [];
  };

  const slots = useMemo(() => {
    let slotsToFind: { label: string; value: string }[] = [];
    const selectedDisplayDate = moment(selectedDate).format(APIDateFormat);
    if (
      providerOpenSlots &&
      selectedSessionDuration &&
      providerOpenSlots[
        selectedSessionDuration as keyof typeof providerOpenSlots
      ] &&
      selectedDate
    ) {
      const availableSlots =
        providerOpenSlots[
          selectedSessionDuration as keyof typeof providerOpenSlots
        ];
      slotsToFind = availableSlots
        .filter((slot) => slot.displayDate === selectedDisplayDate)
        .map(({ displayTime, startTimeUtc }) => ({
          label: displayTime,
          value: startTimeUtc?.toString(),
        }));
    }

    return slotsToFind;
  }, [selectedSessionDuration, selectedDate]);

  const durationList = useMemo(() => {
    const dList = getDurationList(
      providerOpenSlots,
      clientData.credits,
      !!reschedule,
    );

    if (
      clientData.providerRole in clientData.defaultSlotsDuration &&
      dList.includes(clientData.defaultSlotsDuration[clientData.providerRole])
    ) {
      setSelectedSessionDuration(
        clientData.defaultSlotsDuration[clientData.providerRole],
      );
    }
    return dList;
  }, [providerOpenSlots, clientData]);

  const setDurationFun = (item: string) => {
    setSelectedSessionDuration(item);
    setSelectedDateInDatePicker(undefined);
    setSelectedDate(undefined);
    setSelectedSlot('');
  };

  const handleSelectDate = (date?: Date) => {
    setSelectedDate(date);
    setSelectedSlot('');
    setShowDatePicker(false);
  };

  function isDayDisabled(day: Date) {
    return !getAvailableSlotsDates()?.some((disabledDay) =>
      isSameDay(day, new Date(disabledDay)),
    );
  }

  const durationListRadioOptions = useMemo(() => {
    let filteredOptions = durationList;

    if (reschedule?.reason) {
      if (
        reschedule?.reason ===
        ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CHANGE_DURATION
      ) {
        filteredOptions = durationList.filter(
          (item) => item > (reschedule.duration || 0),
        );
      } else {
        filteredOptions = [reschedule.duration];
        setSelectedSessionDuration(reschedule.duration);
      }
    }

    return filteredOptions.map((duration) => ({
      label: t('minutes', { count: parseInt(duration, 10) / 60 }),
      value: duration,
    }));
  }, [durationList, reschedule]);

  const handleResultModalClose = () => {
    setBookSuccessModalData(null);
    handleClose();
    if (!reschedule) {
      onConfirm();
    }
  };

  return (
    <>
      <CreditDetailsCard
        clientData={clientData}
        viewDetailEventLabel="session_booking"
      />
      <div className="mt-8">
        <div className="mb-1 font-medium">{t('DURATION')}</div>
        <InfoTile variant="gray" showIcon content={t('DURATION_SUBTEXT')} />
        <div className="mt-2">
          {!providerOpenSlots || isProviderSlotsLoading ? (
            <div className="flex gap-3">
              <BoxLoader rx={5} width={200} height={44} />
              <BoxLoader rx={5} width={200} height={44} />
              <BoxLoader rx={5} width={200} height={44} />
            </div>
          ) : (
            <RadioButtonGroup
              options={durationListRadioOptions}
              selected={selectedSessionDuration}
              onSelect={(newSelected: string) => setDurationFun(newSelected)}
              disabled={!clientData.credits?.canBook}
            />
          )}
        </div>
        <div className="mt-6 flex justify-between">
          <div className="w-full">
            <Typography size={14} weight="500" color="primary" textAlign="left">
              {t('Date')}
            </Typography>

            <Button
              variant="secondary"
              onClick={() => setShowDatePicker(true)}
              className="w-full text-start font-medium"
              disabled={!durationList.includes(selectedSessionDuration)}
            >
              {selectedDate
                ? moment(selectedDate)
                    .tz(getTimeZone())
                    .format('MMM DD, YYYY - dddd')
                : null}
            </Button>
          </div>
        </div>
        <div className="mt-6 mb-3">
          <div className="text-sm font-medium mb-2">{t('selectStartTime')}</div>
          <SlotSelectList
            options={slots}
            selectedValue={selectedSlot}
            onClick={(newSlot) => setSelectedSlot(newSlot)}
            emptyLabel={{
              show: Boolean(selectedDate) && !slots.length,
              label: 'No available slots, please try a different date.',
            }}
          />
        </div>
        <div className="w-full mt-8">
          <Button
            onClick={() =>
              reschedule
                ? onConfirm({
                    scheduledStartTime: selectedSlot,
                    duration: selectedSessionDuration,
                    role: clientData.providerRole,
                  })
                : bookProviderSessionFun()
            }
            variant="primary"
            className="w-full"
            disabled={!selectedSlot || isBookSessionLoading}
          >
            {reschedule ? t('RESCHEDULE_SESSION') : t('BOOK_SESSION')}
          </Button>
        </div>
      </div>

      <Modal
        handleClose={() => setShowDatePicker(false)}
        open={showDatePicker}
        title={t('SELECT_DATES_HEADER')}
      >
        <DatePicker
          selected={selectedDateInDatePicker}
          onSelect={setSelectedDateInDatePicker}
          disabled={(day) => isDayDisabled(day)}
          classNames={{
            root: 'text-sm',
            day: '[&_button]:w-10 [&_button]:h-10',
          }}
        />

        <div className="w-full text-start font-medium border border-gray-300 my-4 py-2 px-3 h-10 rounded-l">
          {selectedDateInDatePicker
            ? moment(selectedDateInDatePicker)
                .tz(getTimeZone())
                .format('MMM DD, YYYY - dddd')
            : null}
        </div>
        <Button
          className="mt-4 w-full"
          onClick={() => handleSelectDate(selectedDateInDatePicker)}
          disabled={!selectedDateInDatePicker}
        >
          {t('SELECT_DATE_CTA')}
        </Button>
      </Modal>
      <Modal open={!!bookSuccessModalData} handleClose={handleResultModalClose}>
        <SuccessComponentWithTickAnimation
          title={t('SESSION_COMFIRMATION_HEADER')}
          onSubmit={handleResultModalClose}
        >
          {bookSuccessModalData ? (
            <div className="grid grid-cols-3 items-center gap-3">
              {Object.entries(bookSuccessModalData).map(([label, value]) => (
                <div
                  key={label + value}
                  className="px-4 col-span-1 py-2 rounded-lg bg-gray-25 border border-gray-100 text-sm"
                >
                  <div className="font-semibold mb-2">{t(label)}</div>
                  <div>{value}</div>
                </div>
              ))}
            </div>
          ) : null}
        </SuccessComponentWithTickAnimation>
      </Modal>
    </>
  );
}
