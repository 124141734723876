import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { extractInitials } from '../../utilities/common/Avatar';

export default function Avatar({
  clientName,
  className = '',
  imgUrl,
}: Readonly<{
  clientName: string;
  className?: string;
  imgUrl?: string;
}>) {
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(true);
  const onImageError = () => {
    setImageLoaded(false);
  };

  return imageLoaded && imgUrl ? (
    <img
      src={imgUrl}
      alt="profile"
      onError={onImageError}
      className="w-8 h-8 rounded-full"
    />
  ) : (
    <section
      className={twMerge(
        'w-12 h-12 rounded-full flex items-center justify-center md font-semibold text-white bg-rose-800',
        className,
      )}
    >
      {extractInitials(clientName)}
    </section>
  );
}
