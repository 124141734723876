import React, { useState, useRef, useEffect } from 'react';
import { cva } from 'class-variance-authority';
import { Button } from '../../../../v2/view/components/Button';
import { ReactComponent as PlayIcon } from '../../../../v2/assets/Play.svg';
import { ReactComponent as PauseIcon } from '../../../../v2/assets/Pause.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import { ChatSource } from '../../../../shared/types/Chat';
import ProgressBar from '../ProgressBar';

const audioPlayerConfig = cva(
  'relative min-w-[200px] w-fill webkit-fill moz-fill fill flex items-center rounded-md p-2',
  {
    variants: {
      source: {
        outbound: 'bg-primary-200',
        inbound: 'bg-gray-100',
      },
    },
  },
);

interface AudioPlayerProps {
  src: string;
  onDelete?: () => void;
  source: ChatSource;
}

const getAudioDurationFromUrl = async (audioUrl: string) => {
  const audioElement = new Audio(audioUrl);
  await new Promise<void>((resolve) => {
    audioElement.oncanplay = () => {
      resolve();
    };
  });

  const { duration: audioDuration } = audioElement;
  if (audioDuration === Infinity) {
    // Calculate the duration based on the audio's metadata
    try {
      const audioContext = new AudioContext();
      const response = await fetch(audioUrl);
      const audioBuffer = await audioContext.decodeAudioData(
        await response.arrayBuffer(),
      );
      const { sampleRate } = audioBuffer;
      const { length } = audioBuffer;
      const durationInSeconds = length / sampleRate;
      return durationInSeconds;
    } catch {
      return 0;
    }
  }
  return audioDuration;
};

function AudioPlayer({ src, onDelete, source }: Readonly<AudioPlayerProps>) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const iconColor = source === 'outbound' ? '#1570EF' : '#667085';

  // Reset currentTime when src changes
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = 0;
      setCurrentTime(0); // Reset time when audio changes
    }
  }, [src]);

  // Load audio metadata and set duration
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleLoadedMetadata = () => {
        if (audio.duration !== Infinity) {
          setDuration(audio.duration);
        } else {
          getAudioDurationFromUrl(src).then((time: number) => {
            setDuration(time);
          });
        }
      };

      const handleTimeUpdate = () => {
        if (audio) {
          setCurrentTime(Math.ceil(audio.currentTime * 10) / 10);
        }
      };

      // Check for valid audio element and metadata
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('timeupdate', handleTimeUpdate);
      // Fallback: In case audio element does not load correctly
      if (audio.readyState >= 2) {
        // READY_STATE_HAVE_METADATA
        handleLoadedMetadata();
      }

      return () => {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
    return () => {};
  }, []);

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className={audioPlayerConfig({ source })}>
      <audio
        ref={audioRef}
        src={src}
        preload="metadata"
        className="hidden"
        onEnded={() => {
          setIsPlaying(false);
        }}
      />
      <Button
        onClick={togglePlay}
        className="hover:bg-transparent active:focus:bg-transparent p-[5px]"
        variant="ghost"
      >
        {isPlaying ? (
          <PauseIcon style={{ color: iconColor }} height='22px' width='22px' />
        ) : (
          <PlayIcon style={{ color: iconColor }} height='22px' width='22px' />
        )}
      </Button>
      <ProgressBar
        currentValue={currentTime}
        totalValue={duration}
        source={source}
      />
      {!!onDelete && (
        <Button
          variant="secondary"
          onClick={onDelete}
          className="absolute z-10 top-0 right-0 h-6 w-6 rounded-full flex p-[3px] -top-2 -right-2"
        >
          <CloseIcon className="w-4 h-4" />
        </Button>
      )}
    </div>
  );
}

export default AudioPlayer;
