import { cva } from 'class-variance-authority';
import { ChatSource } from '../../../shared/types/Chat';
import { getVoiceNoteElapsedTime } from '../../../utilities/common/Date';

const progressBarConfig = cva(
  'grow ml-2 bg-gray-200 rounded-full h-2 relative cursor-pointer',
  {
    variants: {
      source: {
        outbound: 'bg-primary-300',
        inbound: 'bg-gray-200 ',
      },
    },
  },
);

const currentProgressConfig = cva('ml-2 text-xs', {
  variants: {
    source: {
      outbound: 'text-primary-600',
      inbound: 'text-gray-500 ',
    },
  },
});

interface IProgressBar {
  currentValue: number;
  totalValue: number | null;
  source: ChatSource;
}
export default function ProgressBar({
  currentValue,
  totalValue,
  source,
}: IProgressBar) {
  return (
    <>
      <div className={progressBarConfig({ source })}>
        <div
          className="bg-blue-500 rounded-full h-full transition-all"
          style={{
            width: totalValue ? `${(currentValue / totalValue) * 100}%` : '0%', // Only show progress if totalValue is available
            transition: 'width 0.1s ease-in-out', // Smooth transition effect
          }}
        />
      </div>
      <span className={currentProgressConfig({ source })}>
        {getVoiceNoteElapsedTime(currentValue)}
      </span>
    </>
  );
}
