import _ from 'lodash';
import IrisClient from '../client/IrisClient';
import { MessageChannelTypes } from '../constants/CommonConstants';
import {
  IConversationsMetadata,
  SelectedUserMessagesType,
  IConversationRequestBody,
  IProviderConversationsV2,
  IConversationPages,
  ConversationListDataType,
} from '../data-types/ClientTypes';
import { getConversationsDetailsv2 } from '../services/API/CommonConversation';
import { IMessageSendType, IOnNewMessage, IOnUnreadCount } from '../data-types/ChatBrokerTypes';

export default class ChatBroker {
  private readonly chatClient;

  public conversationList: IConversationsMetadata['userData'];

  public unreadCount: number;

  private conversationBody: IConversationRequestBody;

  public userMessages: SelectedUserMessagesType;

  private selectedConversationId: string;

  private _finalConversationList: IConversationPages;

  constructor() {
    this.chatClient = {
      [MessageChannelTypes.IRIS]: new IrisClient(),
    };
    this.conversationList = [];
    this.unreadCount = 0;
    this.userMessages = {
      messages: [],
      isNext: true,
    } as SelectedUserMessagesType;
    this.selectedConversationId = '';
    this.conversationBody = { page: 1 };
    this._finalConversationList = {
      isNext: true,
      clients: [],
    } as IConversationPages;
    this.getConversationList = this.getConversationList.bind(this);
    this.getAllConversations = this.getAllConversations.bind(this);
    this.getSelectedUserUnreadCount =
      this.getSelectedUserUnreadCount.bind(this);
    this.updateMessageConsumptionStatus =
      this.updateMessageConsumptionStatus.bind(this);
    this.getUserMetadataByUserId = this.getUserMetadataByUserId.bind(this);
  }

  // merge last msg data with meta data along with sorting

  private static _appendMessageToConversation(
    messageList: IConversationPages[],
  ): IConversationPages {
    const conversationList = _.flatten(messageList.map((item) => item.clients));
    const isNext = messageList
      .map((item) => item.isNext)
      .reduce((acc, item) => item || acc, false);
    return {
      isNext,
      clients: conversationList,
    };
  }

  // for separating metadata based on channel type
  private _createChannelWiseData() {
    const registeredChannel = Object.keys(this.chatClient);
    const channelConversationData = Object.fromEntries(
      registeredChannel.map((key) => {
        const tempArray: IConversationsMetadata['userData'] = [];
        return [key, tempArray];
      }),
    );
    this.conversationList.forEach((conversation) => {
      channelConversationData[conversation.channelType].push(conversation);
    });
    return channelConversationData;
  }

  //  initial conversation api call

  public async getConversationList() {
    const resp: IProviderConversationsV2 = await getConversationsDetailsv2(
      this.conversationBody,
    );
    this.unreadCount = resp.data.unreadCount;
    this.conversationList = resp?.data?.data.map((conversation) => ({
      channelId: conversation?.channelExternalId,
      conversationId: conversation?.conversationId,
      userId: conversation?.userId, // sender Id
      friendlyName: conversation?.user?.friendlyName,
      providerRole: conversation?.providerRole,
      tags: conversation?.user?.tags,
      channelType: conversation?.channelType as MessageChannelTypes,
      providerId: conversation?.providerId,
      hasAppAccess: conversation?.hasAppAccess,
      lastMessage: conversation?.lastMessage,
      unreadCount: conversation?.unreadCount,
    }));
    return resp;
  }

  // listening to new messages on socket & appending it to current message list

  public onSocketNewMessage(callBack: IOnNewMessage): void {
    Object.values(this.chatClient).forEach((client) => {
      client.onSocketNewMessage((message) => {
        if (
          !!this.selectedConversationId &&
          message.conversationId === this.selectedConversationId
        ) {
          this.userMessages.messages = [message, ...this.userMessages.messages];
        }
        callBack(message);
      });
    });
  }
  // currently for 1 client this logic will work f9, change in future if 2nd client is implemented
  
  public onSocketUnreadCount(callBack: IOnUnreadCount): void {
    Object.values(this.chatClient).forEach((client) => {
      client.onSocketUnreadCount((message) => {
        this.unreadCount = message.totalUnreadCount;
        callBack(message);
      });
    });
  }

  // getting latest messages of twilio & iris and then merge those list into 1 single list

  async getAllConversations(
    body: IConversationRequestBody,
  ): Promise<IConversationPages> {
    this.conversationBody = body;
    const resp = await this.getConversationList();
    const {
      data: { page },
    } = resp;
    const totalPages = resp.data.pages;
    const channelConversationData = this._createChannelWiseData();
    const promises = Object.entries(this.chatClient).map(async (item) => {
      const [channelType, client] = item;
      return client.getConversationList(
        channelConversationData[channelType],
        page,
        totalPages,
      );
    });

    const data = await Promise.all(promises);
    this._finalConversationList = ChatBroker._appendMessageToConversation(data);
    return this._finalConversationList;
  }

  // getting messages of selected user
  async getSelectedUserMessages(
    selectedConversationId: string,
    channelId: string,
    pageNo: number,
    channelType: MessageChannelTypes,
    refetch: boolean = false,
  ): Promise<SelectedUserMessagesType> {
    if (refetch) {
      return this.userMessages;
    }
    this.selectedConversationId = selectedConversationId;
    const resp = await this.chatClient[channelType].getSelectedUserMessages(
      this.selectedConversationId,
      pageNo,
    );

    this.userMessages = resp;
    return resp;
  }

  /* eslint-disable */
  public async updateMessageConsumptionStatus(
    userId: string,
    channelType: MessageChannelTypes,
  ) {
    await this.chatClient[channelType]?.updateMessageConsumptionStatus(userId);
    return userId;
  }

  public async sendMessage(
    contentToSend: IMessageSendType,
    conversationId: string,
    channelType: MessageChannelTypes,
  ) {
    const resp = await this.chatClient[channelType].sendMessage(
      contentToSend,
      conversationId,
    );

    return resp;
  }

  public getSelectedUserUnreadCount(selectedConversationId: string): number {
    /* Get Unread count of selected user  */

    if (this._finalConversationList.clients.length) {
      const [filter] = this._finalConversationList.clients.filter(
        (conversation) =>
          conversation.conversationId === selectedConversationId,
      );
      return filter.unreadCount || 0;
    }
    return 0;
  }

  public async deleteSelectedMessage(
    conversationId: string,
    messageId: string,
    channelType: MessageChannelTypes,
  ): Promise<boolean> {
    const resp = await this.chatClient[channelType].deleteSelectedMessage(
      conversationId,
      messageId,
    );
    return resp;
  }

  // added this to find the metadat of user when the user is not present in the 1st 50 list
  public async getUserMetadataByUserId(
    userId: number,
  ): Promise<ConversationListDataType> {
    let filteredUser = {} as ConversationListDataType;
    this.conversationBody = { page: 0 };
    while (
      !filteredUser?.conversationId &&
      (this._finalConversationList.isNext || this.conversationBody.page === 0)
    ) {
      this.conversationBody = { page: this.conversationBody.page + 1 };
      await this.getAllConversations(this.conversationBody);
      const [filteredUserNew] = this._finalConversationList.clients.filter(
        (user) => user.userId === userId,
      );
      filteredUser = filteredUserNew;
    }
    return filteredUser;
  }
}
