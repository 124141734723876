import * as React from 'react';
import { ChatSource } from '../../../../shared/types/Chat';
import TextMessageItem from '../TextMessageItemV2';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

interface IAudioMessageItemProps {
  audioUrl: string;
  source: ChatSource;
  body?: string;
}

function AudioMessageItem({ audioUrl, source, body }: Readonly<IAudioMessageItemProps>) {
  return (
    <>
      <AudioPlayer src={audioUrl} source={source} />
      {!!body && (
        <section className="w-full p-2">
          <TextMessageItem source={source} message={body} />
        </section>
      )}
    </>
  );
}

export default AudioMessageItem;
