import './CaseNoteFormHeader.scss';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { CaseNoteOptions, ICaseNoteHeader } from './types';
import Typography from '../Typography';
import { Select } from '../../../v2/view/components/Select';
import { getCaseNoteOptions } from './utils';
import {
  CaseNoteFieldsToDiscardInSwitch,
  CaseNoteFilterEventValues,
  CaseNoteOptionsToDisableInSwitch,
  CaseNoteTypesWhereSwitcherShouldBeShown,
} from './constants';
import { CaseNoteFormType } from '../../../shared/constant/CoachingCaseNotes';
import Modal from '../../../v2/view/components/Modal';
import { ReactComponent as XIcon } from '../../../v2/assets/close.svg';
import useCaseNotesDetails from '../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import { Button } from '../../../v2/view/components/Button';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import useUnload from '../../../utilities/hooks/useUnload/useUnload';

function CaseNoteFormHeader({
  headerText,
  displayDate,
  meetingId,
  formType,
  caseNoteQuestionsData,
  currentFormData,
  isDirty = false,
}: ICaseNoteHeader) {
  const [destinationFormType, setDestinationFormType] =
    useState<CaseNoteOptions | null>(null);

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { track } = useTracking();

  useUnload(!destinationFormType && isDirty);

  const { saveNewcaseNotes, isSaveNewcaseNotesLoading } = useCaseNotesDetails({
    meetingId: meetingId || '',
    formType: formType as CaseNoteFormType,
    afterAddingCaseNotes: () => {
      window.location.reload();
      queryClient.invalidateQueries(['sessionsList']);
      queryClient.invalidateQueries(['caseNote']);
    },
    enabled: false,
  });

  const onSelectFormType = (newType: CaseNoteOptions) => {
    if (newType !== formType) {
      if (
        currentFormData?.caseNotes.length &&
        currentFormData.caseNotes.some((caseNote) => caseNote.note !== '')
      ) {
        setDestinationFormType(newType);

        track(EventNames.switchCaseNoteForm, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.switchCaseNoteForm,
          eventLabel: 'switch_case_note_form',
          eventValue: CaseNoteFilterEventValues[newType],
        });
      } else {
        /**
         * If no data is filled in the form i.e. currentFormData.caseNotes === []
         * OR all notes in the caseNotes array are blank strings ,
         * Switch the type without showing the warning modal.
         */

        track(CaseNoteFilterEventValues[newType], {
          eventAction: EventActions.click,
          eventCategory: EventCategories.save,
          eventLabel: 'save',
        });
        saveNewcaseNotes({
          ...currentFormData,
          formType: newType,
        });
      }
    }
  };

  const onSaveAndSwitch = () => {
    if (currentFormData && destinationFormType) {
      track(CaseNoteFilterEventValues[destinationFormType], {
        eventAction: EventActions.click,
        eventCategory: EventCategories.save,
        eventLabel: 'save',
      });

      saveNewcaseNotes({
        ...currentFormData,
        formType: destinationFormType,
        ...('caseNotes' in currentFormData && {
          caseNotes: currentFormData.caseNotes.filter(
            (item) =>
              !CaseNoteFieldsToDiscardInSwitch[
                formType as keyof typeof CaseNoteFieldsToDiscardInSwitch
              ].includes(item.key),
          ),
        }),
      });
    }
  };

  const onDiscardAndSwitch = () => {
    if (destinationFormType) {
      track(CaseNoteFilterEventValues[destinationFormType], {
        eventAction: EventActions.click,
        eventCategory: EventCategories.discard,
        eventLabel: 'discard',
      });
      saveNewcaseNotes({
        ...currentFormData,
        formType: destinationFormType,
        caseNotes: currentFormData?.caseNotes.length
          ? currentFormData.caseNotes.map((caseNote) => ({
              ...caseNote,
              note: '',
            }))
          : [],
      });
    }
  };

  return (
    <>
      <article className="case-note-header-wrapper">
        <section className="session-container">
          <Typography size={28} weight="600">
            {headerText}
          </Typography>
          <Typography size={16} withColor="#909192">
            {displayDate}
          </Typography>
        </section>

        {CaseNoteTypesWhereSwitcherShouldBeShown.some(
          (type) => type === formType,
        ) ? (
          <Select
            options={getCaseNoteOptions(
              CaseNoteOptionsToDisableInSwitch[
                formType as keyof typeof CaseNoteFieldsToDiscardInSwitch
              ],
            )}
            selected={formType}
            onSelect={(newType) => onSelectFormType(newType as CaseNoteOptions)}
            listBoxClassName="w-[20rem]"
            listboxAnchor="bottom end"
          />
        ) : null}
      </article>

      <Modal
        open={!!destinationFormType}
        handleClose={() => setDestinationFormType(null)}
        bodyClassname="pb-0"
        className="w-[50vw]"
      >
        <div className="text-center font-semibold text-displayXs mb-2">
          {t('SAVE_CHANGES_HEADER')}
        </div>
        <div className="text-center text-gray-600 w-3/4 mx-auto">
          {t('SAVE_CHANGES_SUBTITLE')}
        </div>

        <div className="mt-5 p-6 w-[90%] mx-auto border border-gray-200 rounded-md bg-gray-50 grid grid-cols-2 gap-3 justify-center">
          {caseNoteQuestionsData
            ? CaseNoteFieldsToDiscardInSwitch[
                formType as keyof typeof CaseNoteFieldsToDiscardInSwitch
              ]?.map((field) => {
                const question = caseNoteQuestionsData?.[field];
                return question ? (
                  <div key={question.id} className="flex items-center gap-4">
                    <XIcon className="text-error-500 font-bold w-3 h-3" />
                    {question.question}
                  </div>
                ) : null;
              })
            : null}
        </div>

        <hr className="text-gray-100 my-5" />
        <div className="flex gap-5 items-center w-full">
          <Button
            className="w-full"
            variant="secondary"
            onClick={onDiscardAndSwitch}
          >
            {t('SAVE_CHANGES_SECONDARY_CTA')}
          </Button>
          <Button
            className="w-full"
            onClick={() => onSaveAndSwitch()}
            disabled={isSaveNewcaseNotesLoading}
          >
            {t('SAVE_CHANGES_PRIMARY_CTA')}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default CaseNoteFormHeader;
