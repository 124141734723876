import * as React from 'react';
import { IFloatingChatWindowSettings } from '../types/Chat';
import { IHeaderSettings } from '../../view/app-components/Layout/types';

interface ILayoutContext {
  layoutRef: React.RefObject<HTMLDivElement>;
  updateChatWindowSettings: (chatSettings: IFloatingChatWindowSettings) => void;
  chatWindowVisible: boolean;
  isInFloatingWindow:boolean;
  updateHeaderSettings: (headerSetting: Partial<IHeaderSettings>) => void;
  resetNavigation: () => void;
  initialUser?: number | null;
  viewMode: 'full-view' | 'chat-view';
}

const LayoutContext = React.createContext<ILayoutContext>({} as ILayoutContext);

export default LayoutContext;
