/* eslint-disable */
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { cva } from 'class-variance-authority';
import {
  MessageChannelTypes,
  MessageContentTypes,
} from '../../../constants/CommonConstants';
import { ChatSource } from '../../../../shared/types/Chat';
import AssignmentMessageItem from '../AssignmentMessageItemV2';
import AudioMessageItem from '../AudioMessageItemV2';
import CtaMessageItem from '../CtaMessageItemV2';
import ImageMessageItem from '../ImageMessageItemV2';
import TextMessageItem from '../TextMessageItemV2';
import { MessageResponseType } from '../../../data-types/ClientTypes';
import {
  getFormattedMessagedTime,
  getMessageContentType,
} from '../../../utils/helper';
import DocumentMessageItem from '../DocumentMessageItem';
import Avatar from '../../../../v2/view/components/Avatar';
import useAuth from '../../../../utilities/hooks/useAuth';
import useProviderProfile from '../../../../v2/utilities/hooks/useProviderProfile';
import { getImageUri } from '../../../../utilities/common/Display';
import automatedMessageIcon from '../../../../v2/assets/AutomatedMsg.svg';
// import { Button } from '../../../../v2/view/components/Button';
// import { ReactComponent as DeleteIcon } from '../../../../v2/assets/DeleteTrashIcon.svg';
import { IDeleteMessageType } from '../../../data-types/ChatBrokerTypes';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import useLayout from '../../../../utilities/hooks/useLayout';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventNames,
} from '../../../../shared/constant/Analytics';

const chatBubbleWrapperConfig = cva(
  'w-full flex mb-2 relative gap-3 items-end',
  {
    variants: {
      source: {
        outbound: 'justify-end ;',
        inbound: 'flex-row-reverse justify-end',
      },
    },
  },
);

const messageBubbleConfig = cva(
  'rounded-lg p-3  w-fit border solid flex flex-col gap-4',
  {
    variants: {
      source: {
        outbound: 'justify-end items-end',
        inbound: 'justify-start items-start',
      },
      size: {
        medium: 'max-w-[240px]',
        large: 'max-w-[321px]',
      },
      deleted: {
        true: 'bg-grey-300',
        false: '',
      },
    },
    compoundVariants: [
      {
        source: 'outbound',
        deleted: true,
        className: 'bg-gray-100 border-gray-100',
      },
      {
        source: 'inbound',
        deleted: true,
        className: 'bg-gray-100 border-gray-100',
      },
      {
        source: 'outbound',
        deleted: false,
        className: 'bg-primary-100 border-primary-200',
      },
      {
        source: 'inbound',
        deleted: false,
        className: 'bg-blueDark-50 border-blueDark-100',
      },
    ],
  },
);

const timeContainerConfig = cva(
  'relative bottom-px normal text-xs text-gray-500 flex list-none gap-5 items-center',
  {
    variants: {
      source: {
        outbound: 'justify-end',
        inbound: 'justify-start',
      },
    },
  },
);

export interface IChatMessage {
  message: MessageResponseType;
  source: ChatSource;
  clientId: number;
  updateChatWindowSettings: (settings: any) => void;
  hasAppAccess: boolean;
  clientName: string;
  conversationId: string;
  channelType: MessageChannelTypes;
  messageId: string;
  onDelete: (data: IDeleteMessageType) => void;
  deleted: boolean;
}

function ChatMessage(
  {
    message,
    source,
    clientId,
    updateChatWindowSettings, // eslint-disable-next-line
    hasAppAccess,
    clientName,
    conversationId,
    channelType,
    messageId,
    onDelete,
    deleted,
  }: IChatMessage,
  ref: React.ForwardedRef<HTMLDivElement> | null,
) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { isInFloatingWindow } = useLayout();
  const { track } = useTracking();
  const { providerProfileInfo, isProfileLoading } = useProviderProfile(user.id);
  const messageContentType = getMessageContentType(message.attachments);
  const formattedTime = getFormattedMessagedTime(moment(message.createdAt));
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const profileImageUrl = React.useMemo(() => {
    if (messageContentType === MessageContentTypes.cta) {
      return automatedMessageIcon;
    }
    if (
      !isProfileLoading &&
      providerProfileInfo?.profileImage &&
      source === 'outbound'
    ) {
      return getImageUri(providerProfileInfo?.profileImage);
    }
    return undefined;
  }, [isProfileLoading, providerProfileInfo, source, messageContentType]);

  const avatarName = React.useMemo(() => {
    if (source === 'outbound') {
      return providerProfileInfo?.name ?? '';
    }
    return clientName;
  }, [isProfileLoading, providerProfileInfo, source]);

  const senderName = React.useMemo(() => {
    if (messageContentType === MessageContentTypes.cta) {
      return 'Automated message';
    }
    if (source === 'outbound') {
      return 'You';
    }
    return clientName;
  }, [source, messageContentType]);

  const deleteTracking = (intrumentationInfo: string) => {
    track(EventNames.openChatProvider, {
      eventAction: EventActions.click,
      eventCategory: intrumentationInfo,
      eventLabel: intrumentationInfo,
    });
  };

  const onDeleteIconClick = () => {
    setShowDeleteModal(true);
    deleteTracking('delete_message');
  };
  const onDeleteConfirm = (data: IDeleteMessageType) => {
    deleteTracking('delete_message_confirm');
    onDelete(data);
    setShowDeleteModal(false);
  };

  const deletionMessage = React.useMemo(() => {
    if (source === 'outbound') {
      return 'SENDER_DELETE_MESSAGE';
    }
    return 'RECEIVER_DELETE_MESSAGE';
  }, [source]);

  const size = isInFloatingWindow ? 'medium' : 'large';

  return (
    <div className={chatBubbleWrapperConfig({ source })} ref={ref}>
      
      {/* // TODO : uncomment this when the new design is implemented as an enhancement
      {source === 'outbound' && !deleted && (
        <Button
          variant="secondary"
          onClick={onDeleteIconClick}
          className="my-auto w-8 h-8 px-1.5"
        >
          <DeleteIcon style={{ color: '#D92D20' }} />
        </Button>
      )} */}
      <div className={messageBubbleConfig({ source, size, deleted })}>
        {deleted ? (
          <section className="text-sm italic text-gray-500">
            {t(deletionMessage)}
          </section>
        ) : (
          <>
            {messageContentType === MessageContentTypes.text && (
              <TextMessageItem message={message.message} />
            )}
            {messageContentType === MessageContentTypes.image && (
              <ImageMessageItem
                imageUrl={message.attachments[0].content}
                body={message.message}
                source={source}
              />
            )}
            {messageContentType === MessageContentTypes.pdf && (
              <DocumentMessageItem
                docUrl={message.attachments[0].content}
                docName="PDF DOCUMENT"
                body={message.message}
                source={source}
              />
            )}
            {messageContentType === MessageContentTypes.audio && (
              <AudioMessageItem
                audioUrl={message.attachments[0].content}
                source={source}
                body={message.message}
              />
            )}
            {messageContentType === MessageContentTypes.assignment && (
              <AssignmentMessageItem
                assignmentContent={message.attachments[0].content}
                clientId={clientId}
              />
            )}
            {messageContentType === MessageContentTypes.cta && (
              <CtaMessageItem
                message={message.message}
                ctaContent={message.attachments[0].content}
                source={source}
                updateChatWindowSettings={updateChatWindowSettings}
              />
            )}
            {messageContentType === MessageContentTypes.assignment ? null : (
              <ul className={timeContainerConfig({ source })}>
                <li className="shrink min-w-[20px] truncate md:max-w-[117px]">
                  {senderName}
                </li>
                <li className="shrink-0 list-disc">{formattedTime}</li>
              </ul>
            )}
          </>
        )}
      </div>
      <Avatar
        clientName={avatarName}
        className="w-8 h-8"
        imgUrl={profileImageUrl}
      />
      <DeleteConfirmationModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={() =>
          onDeleteConfirm({ conversationId, channelType, messageId })
        }
      />
    </div>
  );
}

export default React.forwardRef(ChatMessage);
