import * as React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import ImageMessageItem from '../ImageMessageItemV2';
import { IMessageSendType } from '../../../data-types/ChatBrokerTypes';
import { MessageSendType } from '../../../constants/CommonConstants';
import pdfIcon from '../../../../v2/assets/PDFFile.svg';
import { byteToMegaByte } from '../../../utils/helper';
import { Button } from '../../../../v2/view/components/Button';

interface AttachmentPreviewProps {
  attachmentFile: IMessageSendType;
  onClose: () => void;
}
function AttachmentPreview({
  attachmentFile,
  onClose,
}: Readonly<AttachmentPreviewProps>) {
  const file = attachmentFile.data as File;
  const url =
    attachmentFile.type === MessageSendType.IMAGE
      ? URL.createObjectURL(file)
      : pdfIcon;

  return (
    <motion.div
      className="bottom-full h-max w-full flex justify-start items-center box-border bg-white z-[3] pb-3"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="relative flex gap-[5px] pl-3 pr-4 py-3 rounded-lg bg-gray-100 w-max">
        <ImageMessageItem
          imageUrl={url}
          disabled={attachmentFile.type !== MessageSendType.IMAGE}
          height="48px"
          width="48px"
        />
        {attachmentFile.type !== MessageSendType.IMAGE && (
          <div className="m-auto w-full">
            <section className="max-w-[150px] text-sm font-medium text-gray-700 truncate">
              {file.name}
            </section>
            <section className="text-xs normal text-gray-500 truncate">
              {byteToMegaByte(file.size)} MB
            </section>
          </div>
        )}
        <Button
          className="absolute z-10 top-0 right-0 h-6 w-6 rounded-full flex p-[5px] -top-2.5 -right-2.5"
          variant="secondary"
          onClick={onClose}
        >
          <FontAwesomeIcon
            icon={faXmark}
            style={{ color: '#767D83' }}
            size="sm"
          />
        </Button>
      </div>
    </motion.div>
  );
}

export default AttachmentPreview;
