import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import { isEmpty } from 'lodash';
import { cva } from 'class-variance-authority';
import useTracking from '../../../utilities/hooks/useTracking';
import ChatContact from './ChatContactV2';
import NoDataFound from '../../../v2/view/components/NoDataFound';
import { useChatBroker } from '../../broker/useChatBroker';
import { getLastMessagedTimeLabel } from '../../utils/helper';
import LiveChat from './LiveChatV2';
import {
  ConversationListDataType,
  IConversationPages,
  IConversationRequestBody,
} from '../../data-types/ClientTypes';
import MessageUtilityPanel from './MessageUtilityPanel';
import useInfiniteScroll from '../../utils/hooks/useInfiniteScroll';
import { ReactComponent as SearchIcon } from '../../../v2/assets/magnifier.svg';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import useLayout from '../../../utilities/hooks/useLayout';
import { ChatHeaderComponents } from './ChatHeader/ChatHeaderComponents/ChatHeaderComponents';
import { ReactComponent as NoChatIcon } from '../../../v2/assets/NoDataFound.svg';
import { ChatListItemLoader } from './LiveChatV2/LiveChatLoader';

const messageWrapperConfig = `
w-full 
box-border 
grid
bg-white 
rounded-2xl
transition-[grid-template-columns]
duration-500
overflow-hidden
`;

const chatContactsWrapperConfig = cva('py-4 px-3 w-full overflow-y-auto', {
  variants: {
    size: {
      small: 'h-[calc(100vh-291px)]',
      large: 'h-[calc(100vh-410px)]',
    },
  },
});

interface IMessagesWrapper {
  viewMode?: 'full-view' | 'chat-view';
}

function MessagesWrapper({
  viewMode = 'full-view',
}: Readonly<IMessagesWrapper>) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const { initialUser, isInFloatingWindow } = useLayout();
  const {
    allConversationList,
    isLoading,
    liveChatData,
    updateLatestConversation,
    getUserMetadataById,
    isNextChatLoaded,
  } = useChatBroker();
  const pageNo = React.useRef<number>(1);
  const prevMessageLength = React.useRef(0);
  const chatListRef = React.useRef<HTMLDivElement[]>([]);
  const [allConversationListCopy, setAllConversationListCopy] = React.useState<
    IConversationPages | undefined
  >(undefined);
  const [selectedChat, setSelectedChat] =
    React.useState<ConversationListDataType>({} as ConversationListDataType);
  const [showQuickOverview, setShowQuickOverview] = React.useState(false);
  const [showBookSessionModal, setShowBookSessionModal] = React.useState(false);
  const [messageFilters, setMessageFilters] =
    React.useState<IConversationRequestBody>({
      page: 1,
    });

  function onIntersect() {
    if (
      !allConversationList?.clients?.length ||
      !allConversationList?.isNext ||
      isLoading
    )
      return;
    updateLatestConversation({
      ...messageFilters,
      page: messageFilters.page + 1,
    });
  }

  const { chatContainerRef, topMessageElementRef } = useInfiniteScroll(
    onIntersect,
    [allConversationList],
    [allConversationList],
    true,
  );

  function onConversationSelect(conversation: ConversationListDataType) {
    track(EventNames.messages, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.viewUserChat,
      eventLabel: 'view_user_chat',
      eventValue: conversation?.userId.toString(),
    });
    setSelectedChat(conversation);
    setShowQuickOverview(false);
    pageNo.current = 1;
    liveChatData.getSelectedUserMessages({
      selectedConversationId: conversation.conversationId,
      channelId: conversation.channelId,
      pageNo: pageNo.current,
      channelType: conversation.channelType,
    });
  }

  React.useLayoutEffect(() => {
    if (allConversationList?.clients && isNextChatLoaded.current) {
      setAllConversationListCopy(allConversationList);
      const newMessageLength = allConversationList?.clients.length;
      const currentViewIndex = newMessageLength - prevMessageLength.current;
      chatListRef.current[currentViewIndex]?.scrollIntoView(true);
      prevMessageLength.current = newMessageLength;
      isNextChatLoaded.current = false; // eslint-disable-line
    }
  }, [allConversationList?.clients]);

  const userRecentMessage = (
    selectedConversation: ConversationListDataType,
  ) => {
    if (selectedConversation.deleted) {
      return t('SENDER_DELETE_MESSAGE');
    }
    return (
      selectedConversation.lastMessageContent ||
      (selectedConversation?.attachmentType !== undefined
        ? t('SHARED_FILE')
        : '')
    );
  };

  const onFilterChange = (data: IConversationRequestBody) => {
    updateLatestConversation(data);
    setMessageFilters(data);
  };

  const isFilterApplied = React.useMemo(() => {
    if (
      messageFilters.readStatus ||
      messageFilters?.searchTerm?.length ||
      messageFilters?.riskLevel?.length
    ) {
      return true;
    }
    return false;
  }, [messageFilters]);

  React.useEffect(() => {
    if (initialUser && allConversationList?.clients.length) {
      getUserMetadataById(initialUser).then((userMetadata) => {
        setSelectedChat(userMetadata);
        onConversationSelect(userMetadata);
      });
    }
  }, [initialUser, allConversationList]);

  const emptyConversationList = allConversationList?.clients?.length === 0;
  const noResultsHeader = isFilterApplied
    ? t('NO_RESULTS_FOUND_HEADER')
    : t('EMPTY_STATE_HEADER_MESSAGES');
  const noResultsDescription = isFilterApplied
    ? t('NO_RESULTS_FOUND_SUBTEXT')
    : t('EMPTY_STATE_SUBTEXT_MESSAGES');

  const size = isInFloatingWindow ? 'large' : 'small';
  return (
    <section className={twMerge(messageWrapperConfig)}>
      <section className="w-full flex col-span-12 overflow-hidden">
        {viewMode === 'full-view' && (
          <section className="min-w-[360px] w-40% col-span-4 border-r solid border-gray-200">
            <MessageUtilityPanel
              title={t('MESSAGES_HEADER')}
              tooltipText={t('settings')}
              onFiltersChange={onFilterChange}
            />
            <section
              ref={chatContainerRef}
              className={chatContactsWrapperConfig({ size })}
              onScroll={(e) => e.stopPropagation()}
            >
              {!isLoading && emptyConversationList && (
                <NoDataFound
                  title={noResultsHeader}
                  description={noResultsDescription}
                  Icon={isFilterApplied ? SearchIcon : NoChatIcon}
                />
              )}
              {isLoading && <ChatListItemLoader count={8}/>}
              <section>
                {!emptyConversationList && allConversationListCopy?.clients?.map(
                  (conversation: ConversationListDataType, index) => (
                    <ChatContact
                      key={conversation.conversationId}
                      name={conversation.friendlyName}
                      message={userRecentMessage(conversation)}
                      messagesUnread={conversation.unreadCount}
                      messagedTime={t(
                        getLastMessagedTimeLabel(
                          moment(),
                          moment(conversation?.lastMsgCreatedAt),
                        ),
                      )}
                      selected={
                        selectedChat.conversationId ===
                        conversation.conversationId
                      }
                      onClick={() => onConversationSelect(conversation)}
                      ref={(el) => {
                        chatListRef.current[index] = el as HTMLDivElement;
                      }}
                    />
                  ),
                )}
                <section ref={topMessageElementRef} />
              </section>
            </section>
          </section>
        )}
        <section className="w-full">
          <LiveChat
            userMetaData={selectedChat}
            getUnReadCount={liveChatData.getSelectedUserUnreadCount}
            selectedUserMsg={liveChatData.selectedUserData}
            isLoading={liveChatData.isLoading}
            getSelectedUserMessages={liveChatData.getSelectedUserMessages}
            pageNoRef={pageNo}
            updateMessageConsumptionInfo={
              liveChatData.updateMsgConsumptionStatus
            }
            isNextMessageLoaded={liveChatData.isNextMessageLoaded}
            setShowQuickOverview={setShowQuickOverview}
            showBookModal={() => setShowBookSessionModal(true)}
          />
        </section>
        {!isEmpty(selectedChat) && !isInFloatingWindow ? (
          <ChatHeaderComponents
            providerRole={selectedChat.providerRole}
            userId={selectedChat.userId}
            showBookModal={showBookSessionModal}
            handleBookModalClose={() => setShowBookSessionModal(false)}
            showQuickOverview={showQuickOverview}
          />
        ) : null}
      </section>
    </section>
  );
}
export default MessagesWrapper;
