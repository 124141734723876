import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useQueryClient } from 'react-query';
import {
  availableLanguages,
  changeLanguage,
  getCurrentLocale,
} from '../../../../i18n';
import ChangeLanguagePopup from '../../../../view/app-components/ChangeLanguagePopup';
import { ReactComponent as GlobeIcon } from '../../../assets/globe.svg';
import { ReactComponent as ChevronDown } from '../../../assets/ChevronDown.svg';
import { Menu, MenuItem } from '../../components/Menu';
import { Button } from '../../components/Button';

function LanguageSwitcher() {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const currentLocale = getCurrentLocale();
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(
    () => currentLocale,
  );
  const [showPopup, setShowPopup] = React.useState<boolean>(false);

  const refreshCacheWithLocales = () => {
    queryClient.invalidateQueries(['caseNote']);
  };

  const onChangeLanguage = () => {
    changeLanguage(selectedLanguage);
    setShowPopup(false);
    refreshCacheWithLocales();
    toast.success(t('successfullyChangedLang'));
  };

  const handleClosePopup = () => {
    setSelectedLanguage(currentLocale);
    setShowPopup(false);
  };

  const onLanguageSelect = (value: string) => {
    if (value !== selectedLanguage) {
      setSelectedLanguage(value);
      setShowPopup(true);
    }
  };

  return (
    <div className="lang-switcher">
      <ChangeLanguagePopup
        open={showPopup}
        onClose={() => handleClosePopup()}
        selectedLangLabel={
          availableLanguages.find((lang) => lang.value === selectedLanguage)
            ?.label || 'English'
        }
        onChangeLanguage={onChangeLanguage}
      />

      {/* <div
        role="button"
        tabIndex={0}
        className="action-btn"
        onClick={() => setShowList((prev) => !prev)}
      >
        <Typography withColor="#636C73" size={12} weight="400">
          {t('selectedLanguage', { lang: getCurrentLocale().toUpperCase() })}
        </Typography>
        <motion.img
          animate={showList ? { rotateZ: '180deg' } : {}}
          src={chevronDownIcon}
          alt="more"
        />
      </div>
      <AnimatePresence>
        {showList && (
          <motion.div
            className="lang-dropdown"
            initial={{ opacity: 0, translateY: '-200px' }}
            animate={{ opacity: 1, translateY: '-90px' }}
            exit={{ opacity: 0, translateY: '-200px' }}
            transition={{ duration: 0.4 }}
          >
            <RadioGroup
              name="languages"
              values={availableLanguages}
              onChange={(lang) => onRadioChange(lang)}
              selectedValue={selectedLanguage}
            />
          </motion.div>
        )}
      </AnimatePresence> */}

      <Menu
        render={
          <Button
            variant="ghost"
            startIcon={<GlobeIcon className="w-4 h-4" />}
            endIcon={<ChevronDown />}
          >
            {availableLanguages.find((lang) => lang.value === currentLocale)
              ?.label || 'English'}
          </Button>
        }
      >
        {availableLanguages.map((lang) => (
          <MenuItem
            onClick={() => onLanguageSelect(lang.value)}
            label={lang.label}
            key={`lang+${lang.label}`}
          />
        ))}
      </Menu>
    </div>
  );
}

export default LanguageSwitcher;
