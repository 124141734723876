export enum StorageItems {
  USER_INFO = 'userInfo',
  CHAT_TOKEN = 'chatToken',
  ROOT_STATE = 'rootState',
  APP_LANGUAGE = 'appLanguage',
  IRIS_ACCESS_TOKEN = 'irisAccessToken',
  CALENDAR_DEFAULT_VIEW = 'calendarDefaultView',
  CALENDAR_CONNECTION = 'calendarConnection',
}

export const VideoRoomSDKs = {
  twilio: '0',
  agora: '1',
  ms: '2',
  f2f: '3',
  zoom: '4', //* SDK type other than defined type will fall back to type 4 ie., twilio
  dailyCo: '5',
} as const;

export const PlatformCode = '5';

/**
 * Once a screen and its route is removed, add it
 * to this array. Users going
 * to that route will be redirected to the home page.
 */
export const oldRemovedRoutes = ['/sessions', '/schedule'];
