import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { getTranslation } from '../../../../i18n/utilities';
import {
  getAssignmentById,
  sendAssignmentReminder,
} from '../../../../shared/redux/actions/assignment';
import { ClientAssignment } from '../../../../shared/types/response/assignment';
import { getImageUri } from '../../../../utilities/common/Display';
import { getAssignmentProgressData } from '../../../../utilities/common/Provider';
import { useFetch, useMutation } from '../../../../utilities/hooks/useFetch';
import { Button } from '../../../../v2/view/components/Button';
import ClientOverviewCardLoader from '../../../../v2/view/screens/ClientDetails/ClientOverviewCardLoader';
import TextMessageItem from '../TextMessageItemV2';

const assignmentBubbletWrapper =
  'min-w-[100px] w-full border-box p-3 flex gap-3 flex-col items-center justify-between bg-primary-200 rounded-lg';
interface IAssignmentMessageItemProps {
  assignmentContent: string;
  clientId: number;
}

function AssignmentMessageItem({
  assignmentContent,
  clientId,
}: Readonly<IAssignmentMessageItemProps>) {
  const { assignmentId = '' } = JSON.parse(assignmentContent);
  const { t } = useTranslation();

  const [reminderSent, setReminderSent] = React.useState<boolean>(false);

  const { isLoading, data } = useFetch<ClientAssignment>(
    ['get-assignment', assignmentId],
    getAssignmentById(assignmentId),
    {
      initialData: {
        title: '',
        mainImage: '',
        translationKeys: {},
      } as ClientAssignment,
    },
  );

  const { mutate: sendReminder } = useMutation(
    sendAssignmentReminder(clientId?.toString(), assignmentId),
    {
      onSuccess: (response: string) => {
        if (response === 'success') {
          toast.success(t('sentReminder'));
          setReminderSent(true);
        }
      },
      onError: (error: Error) => {
        let errMsg = error.message;
        if (error.cause === 422) errMsg = t('alreadySentReminder');
        toast.error(errMsg);
      },
    },
  );

  const {
    title,
    mainImage,
    translationKeys,
    assignmentCategory,
    referenceType,
    notes,
    status,
  } = data as ClientAssignment;

  const assignmentProgress = getAssignmentProgressData(status) || {};

  if (isLoading) {
    return (
      <section className="grid grid-row-8 gap-4">
        <ClientOverviewCardLoader variant="credits" />
      </section>
    );
  }

  return (
    <>
      <section className={assignmentBubbletWrapper}>
        <section className="flex justify-between">
          <section className="w-full flex gap-2 items-start">
            <img
              src={getImageUri(mainImage)}
              className="w-[68px] h-[68px] rounded-lg"
              alt="questionnaire-illustration"
            />
            <section className="w-4/5 flex flex-col gap-1.5">
              <section className="text-sm font-medium text-gray-900 line-clamp-2">
                {referenceType === 'custom'
                  ? title
                  : getTranslation(title, translationKeys)}
              </section>{' '}
              <section className="text-xs text-gray-500">
                {assignmentCategory}
              </section>
            </section>
          </section>
        </section>
        {assignmentProgress.cta && (
          <Button
            disabled={reminderSent}
            onClick={() => sendReminder()}
            className="w-full disabled:bg-primary-300"
          >
            {t(assignmentProgress.cta)}
          </Button>
        )}
      </section>
      {notes && (
        <section className="w-full">
          <TextMessageItem source="outbound" message={notes} />
        </section>
      )}
    </>
  );
}

export default AssignmentMessageItem;
