export enum IrisSocketEventsType {
  NEW_MESSAGE = 'new_message',
  DELETED_MESSAGE = 'message_deleted',
  NEW_CLIENT = 'new_client',
  CLIENT_UPGRADE = 'client_upgrade',
  UNREAD_COUNT = 'unread_count',
}

export enum IrisAttachmentsType {
  IMAGE = 0,
  AUDIO = 2,
  ASSIGNMENT = 4,
  CTA = 5,
  // VIDEO, // todo will be implemented in future
  // FILE,
}
