import { useTranslation } from 'react-i18next';
import Modal from '../../../v2/view/components/Modal';
import { Button } from '../../../v2/view/components/Button';
import { ReactComponent as ConfirmIcon } from '../../../v2/assets/ConfusedIcon.svg';

interface IDeleteConfirmationModal {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

function DeleteConfirmationModal({
  open,
  onClose,
  onDelete,
}: Readonly<IDeleteConfirmationModal>) {
  const { t } = useTranslation();

  return (
    <Modal
      title=""
      open={open}
      showCloseButton={false}
      handleClose={() => {}}
      className="w-5/12"
    >
      <section className="w-full text-center flex flex-col justify-between items-center gap-9">
        <ConfirmIcon className="m-auto" />
        <section className="flex flex-col gap-2 ">
          <section className="text-2xl text-gray-900 font-semibold">
            {t('DELETE_HEADER')}
          </section>
          <section className="text-sm text-gray-600">
            {t('DELETE_SUBTEXT')}
          </section>
        </section>
        <section className="w-full flex gap-3 border-t solid border-gray-200 ">
          <Button variant="secondary" className="w-6/12 mt-6" onClick={onClose}>
            {t('CANCEL_CTA')}
          </Button>
          <Button variant="primary" className="w-6/12 mt-6" onClick={onDelete}>
            {t('DELETE_CTA')}
          </Button>
        </section>
      </section>
    </Modal>
  );
}

export default DeleteConfirmationModal;
