import moment from 'moment';
import { round } from 'lodash';
import { useTranslation } from 'react-i18next';
import { MessageContentTypes } from '../constants/CommonConstants';
import { AttachmentMetaDataTypes } from '../data-types/ClientTypes';
import {
  OldChatContactFormat,
  OldChatMessageFormat,
  RecentChatMessageFormat,
} from '../../utilities/common/Date';

export const getMessageContentType = (
  attachmentData: AttachmentMetaDataTypes[],
) => {
  const [attachmentType] = attachmentData;
  if (!attachmentType) return MessageContentTypes.text;
  return attachmentType.type;
};

export function delay(time: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('');
    }, time);
  });
}

export const byteToMegaByte = (byte: number) => round(byte / (1024 * 1024), 2);

export const getLastMessagedTimeLabel = (
  currentDate: moment.Moment,
  lastMessagedDate: moment.Moment,
): string => {
  if (!lastMessagedDate.isValid()) {
    return '';
  }
  const isSameDay = currentDate.startOf('day') <= lastMessagedDate;
  const isYesterday =
    currentDate.subtract('1', 'day').startOf('day') <= lastMessagedDate;
  if (isSameDay) {
    return lastMessagedDate.format(RecentChatMessageFormat);
  }
  if (isYesterday) {
    return 'yesterday';
  }

  return lastMessagedDate.format(OldChatContactFormat);
};

export const getFormattedMessagedTime = (
  messagedDate: moment.Moment,
): string => {
  const { t } = useTranslation();
  const currentDate = moment();
  const isSameDay = currentDate.startOf('day') <= messagedDate;
  const isYesterday =
    currentDate.subtract('1', 'day').startOf('day') <= messagedDate;
  if (isSameDay) {
    return messagedDate.format(RecentChatMessageFormat);
  }
  if (isYesterday) {
    return t('yesterdayWithTime', {
      time: messagedDate.format(RecentChatMessageFormat),
    });
  }

  return messagedDate.format(OldChatMessageFormat);
};
