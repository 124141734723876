import moment from 'moment';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../../utilities/hooks/useAuth';
import { PresentingIssueAuthor } from '../../../shared/constant/Clients';
import { ClientProfileDateFormat } from '../../../utilities/common/Date';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import InfoTile from '../../components/InfoTile';
import UnOrderedList from '../../components/UnOrderedList';
import ClientDetailsContext from './context/ClientDetailsContext';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
import { getDisplayDate } from '../../../../utilities/common/Date';
import { getCurrentLocale } from '../../../../i18n';

export default function UserPresentingIssuesCard() {
  const { clientDetailData, isLoading, context } =
    useContext(ClientDetailsContext);

  const { user } = useAuth();
  const { t } = useTranslation();
  const currentLocale = getCurrentLocale();

  const clientName = clientDetailData?.profile?.name;
  const presentingIssue = clientDetailData?.presentingIssue;
  const addedBy = presentingIssue?.addedBy;
  let authorName = '';

  if (addedBy !== undefined) {
    if (addedBy === 0) {
      authorName = t('ADDED_BY_ACTOR_PROVIDER', {
        provider_name: user?.name ?? '',
      });
    } else if (addedBy === 1) {
      authorName = t('ADDED_BY', {
        actor_name: clientDetailData?.profile?.name || '-',
      });
    } else {
      authorName = t('ADDED_BY', {
        actor_name: PresentingIssueAuthor?.[addedBy],
      });
    }
  }

  const PISubContentItems = [];

  if (authorName) {
    PISubContentItems.push({
      label: (
        /* eslint-disable-next-line */
        <span dangerouslySetInnerHTML={{ __html: authorName }} />
      ),
    });
  }

  if (presentingIssue) {
    PISubContentItems.push({
      label: getDisplayDate({
        locale: currentLocale,
        translatorResource: { ja: 'DISPLAY_DATE_WITH_TIME' },
        format: { ja: 'MM,DD,YYYY,HH,mm', en: ClientProfileDateFormat },
        selectedDate: moment(presentingIssue?.createdAt),
        translate: t,
      }),
    });
  }

  if (isLoading) {
    return <ClientOverviewCardLoader variant="card" />;
  }

  return (
    <BasicCardTemplate
      heading={t('PRESENTING_ISSUES_HEADER')}
      tooltipContent={t('PRESENITNG_ISSUE_TOOLTIP')}
      showToolTip
      className={context === 'messages' ? 'border-none shadow-none rounded-none' : ''}
    >
      {!presentingIssue?.sessionCompleted && (
        <InfoTile
          lineClamp={2}
          variant="error"
          contentClassName="text-error-600"
          showIcon
          content={t('PRESENTING_ISSUES_ERROR_STATE_1', { clientName })}
        />
      )}

      {presentingIssue?.sessionCompleted && presentingIssue?.pending ? (
        <InfoTile
          lineClamp={2}
          variant="error"
          contentClassName="text-error-600"
          showIcon
          content={t('PRESENTING_ISSUES_ERROR_STATE_2', { clientName })}
        />
      ) : null}

      {presentingIssue?.issue ? (
        <InfoTile
          variant="primary"
          content={
            <span className="text-xs font-semibold text-gray-900">
              {presentingIssue?.issue ?? '-'}
            </span>
          }
          subContent={
            <UnOrderedList
              className="text-gray-500 flex flex-wrap truncate"
              items={PISubContentItems}
            />
          }
        />
      ) : null}
    </BasicCardTemplate>
  );
}
